import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import InputField from 'components/form_fields/input_field';
import Typeahead from 'components/form_fields/typeahead';
import { loadInsurers, loadEmployers } from 'components/landing_page/utils/load_typeahead_data';
import LoadingSquare from 'components/shared/loading_square';

const TIMEOUT = 'timeout';
export const SERVER_ERROR = 'server_error';

function GenericCoverageCheckForm({
  allianceLevelLandingPage,
  allowUnemployedOption,
  onCoverageCheckResult,
  orangeHealthInsurerOrganization,
}) {
  const DEFAULT_SUBTITLE =
    'Fill out the information below, and we’ll check to see if your employer ' +
    'or health insurance offers Omada as a benefit. (All fields are required.)';
  const ALLIANCE_PAGE_SUBTITLE =
    'Fill out the information below, and we’ll check to see if you are ' +
    'covered for Omada as a benefit. (All fields are required.)';
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [zip, setZip] = useState('');
  const [employerId, setEmployerId] = useState('');
  const [employer, setEmployer] = useState('');
  const [noEmployer, setNoEmployer] = useState(false);
  const [healthInsurer, setHealthInsurer] = useState('');
  const [healthInsurerId, setHealthInsurerId] = useState('');
  const [noHealthInsurer, setNoHealthInsurer] = useState(false);
  const [errors, setErrors] = useState({});
  const [runningCoverageCheck, setRunningCoverageCheck] = useState(false);

  const healthInsurerRequired = !allianceLevelLandingPage && !noHealthInsurer;
  const employerRequired = !allianceLevelLandingPage && !noEmployer;

  const isDisabled = () => {
    return (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      (healthInsurer === '' && healthInsurerRequired) ||
      (employer === '' && employerRequired) ||
      Boolean(errors.zip) ||
      zip === ''
    );
  };

  const validateZip = () => {
    const regex = /^[0-9]{5}$/;
    return regex.test(zip);
  };

  useEffect(() => {
    if (errors.zip && validateZip()) setErrors({ ...errors, zip: null });
  }, [zip]);

  const handleEmployerChange = (inputValue, noEmployerSelected = false) => {
    if (noEmployerSelected) {
      setNoEmployer(true);
      setEmployer(null);
      setEmployerId(null);
    } else {
      setNoEmployer(false);
      setEmployer(inputValue.label || inputValue);
      setEmployerId(inputValue.value || '0');
    }
  };

  const handleHealthInsurerChange = (inputValue, noHealthInsurerSelected = false) => {
    if (noHealthInsurerSelected) {
      setNoHealthInsurer(true);
      setHealthInsurer(null);
      setHealthInsurerId(null);
    } else {
      setNoHealthInsurer(false);
      setHealthInsurer(inputValue.label || inputValue);
      setHealthInsurerId(inputValue.value || '0');
    }
  };

  const postGCCData = () => {
    const healthInsurerName = allianceLevelLandingPage ? orangeHealthInsurerOrganization.name : healthInsurer;
    const orangeHealthInsurerOrganizationId = allianceLevelLandingPage
      ? orangeHealthInsurerOrganization.orange_database_id
      : healthInsurerId;

    const data = {
      generic_coverage_check: {
        first_name: firstName,
        last_name: lastName,
        email,
        employer_name: employer,
        orange_employer_organization_id: employerId,
        employer_not_known: allianceLevelLandingPage || noEmployer,
        health_insurer_name: healthInsurerName,
        orange_health_insurer_organization_id: orangeHealthInsurerOrganizationId,
        health_insurer_not_known: noHealthInsurer,
        attribution_session_id: window.dataFromRuby.attribution_session_id,
        zip,
      },
      allow_unemployed_option: allianceLevelLandingPage || allowUnemployedOption,
    };

    $.ajax('/generic_coverage_checks/', {
      method: 'POST',
      beforeSend(xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      data,
      timeout: 15000,
      success: (fetchedData) => {
        onCoverageCheckResult(fetchedData);
      },
      error: (response) => {
        setRunningCoverageCheck(false);
        if (response.statusText === TIMEOUT) {
          onCoverageCheckResult({ results_page: SERVER_ERROR });
        }
        if (response.status >= 500) {
          onCoverageCheckResult({ results_page: SERVER_ERROR });
        }
        if (response.responseJSON && response.responseJSON.errors) {
          setErrors(response.responseJSON.errors);
        }
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    setRunningCoverageCheck(true);
    postGCCData();
  };

  const renderCoverageCheckForm = () => {
    return (
      <>
        <h1 className='u-h3 u-4mb--xxs gcc__header qa-gcc-title'>You may be able to join Omada at no cost to you!</h1>
        <p className='u-4mb--s qa-gcc-subtitle'>
          {allianceLevelLandingPage ? ALLIANCE_PAGE_SUBTITLE : DEFAULT_SUBTITLE}
        </p>
        <form className='g-container--full u-4pb--xxxl qa-gcc-form' onSubmit={(e) => handleSubmit(e)}>
          <div className='g-row'>
            <InputField
              fieldName='first_name'
              label='First Name'
              value={firstName}
              errors={errors}
              onChange={(e) => setFirstName(e.target.value)}
              containerClasses='g-col-6 u-4mt--xs'
              autoComplete='given-name'
            />
            <InputField
              fieldName='last_name'
              label='Last Name'
              value={lastName}
              errors={errors}
              onChange={(e) => setLastName(e.target.value)}
              containerClasses='g-col-6 u-4mt--xs u-pr--n'
              autoComplete='family-name'
            />
            <div className='gcc__form-helper-text u-4mt--xs u-4mb--m'>
              Please enter your first and last name as it appears on an official ID.
            </div>
          </div>
          <div className='g-row'>
            <InputField
              fieldName='email'
              type='email'
              label='Email'
              placeholder='example@gmail.com'
              maxLength='100'
              value={email}
              errors={errors}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete='email'
            />
          </div>
          <div className='g-row'>
            <InputField
              fieldName='zip'
              type='text'
              label='Zip Code'
              value={zip}
              errors={errors}
              onChange={(e) => setZip(e.target.value)}
              onBlur={() => {
                if (!validateZip()) {
                  setErrors({ ...errors, zip: ['Zip must be 5 digits'] });
                } else {
                  setErrors({ ...errors, zip: null });
                }
              }}
              containerClasses='g-col-6 u-4mb--m'
              autoComplete='postal-code'
            />
            <div className='g-col-6' />
          </div>
          {!allianceLevelLandingPage && (
            <>
              <div className='g-row'>
                <Typeahead
                  containerClassNames='g-col u-4mb--m'
                  fieldName='employer_name'
                  label='Employer'
                  onChange={handleEmployerChange}
                  radioValue={noEmployer}
                  onLoadOptions={loadEmployers}
                  onRadioChange={(event) => handleEmployerChange(event, true)}
                  errors={errors}
                  placeholder='E.g. Costco, Xerox'
                  tooltipContent='Enter the employer who offers your health benefits.'
                  showRadio={allowUnemployedOption}
                  radioText='Retired or Not Employed'
                />
              </div>
              <div className='g-row'>
                <Typeahead
                  containerClassNames='g-col u-4mb--m'
                  fieldName='health_insurer_name'
                  label='Health Insurance'
                  onChange={handleHealthInsurerChange}
                  radioValue={noHealthInsurer}
                  onLoadOptions={loadInsurers}
                  onRadioChange={(event) => handleHealthInsurerChange(event, true)}
                  errors={errors}
                  placeholder='E.g. Aetna, Oscar, State of Alaska'
                  tooltipContent='Enter the name of your health insurance provider.'
                  showRadio
                  radioText='None/Not Sure'
                />
              </div>
            </>
          )}
          <p className='t-bg--grey-2 u-4p--m u-4mt--m u-4mb--xxl gcc__form-legal-text'>
            As a health care provider and a covered entity under HIPAA, Omada takes your privacy seriously. For more
            information about how Omada maintains and uses information about you, please refer to the&nbsp;
            <a href='https://www.omadahealth.com/terms-of-use' className='t-c--main'>
              Terms of Use
            </a>
            ,&nbsp;
            <a href='https://www.omadahealth.com/privacy-policy' className='t-c--main'>
              Privacy Policy
            </a>
            , and&nbsp;
            <a href='https://www.omadahealth.com/hipaa-notice' className='t-c--main'>
              Notice of HIPAA Privacy Practices
            </a>
            .
          </p>
          <div className='g-row u-4pt--s u-4mb--m'>
            <button
              type='submit'
              className='c-btn--primary u-m--a qa-gcc-coverage-check-button'
              disabled={isDisabled()}
            >
              Check Coverage
            </button>
          </div>
        </form>
      </>
    );
  };

  const renderLoadingScreen = () => {
    return (
      <div className='u-flex u-justify-content-center u-4pb--xl'>
        <LoadingSquare loadingMessage='Checking coverage...' gifClasses='green-loading-gif u-4mt--l' />
      </div>
    );
  };

  return (
    <div className='gcc__form-width gcc__form-container u-m--a'>
      {runningCoverageCheck ? renderLoadingScreen() : renderCoverageCheckForm()}
    </div>
  );
}

GenericCoverageCheckForm.defaultProps = {
  allianceLevelLandingPage: false,
  allowUnemployedOption: false,
};

const { bool, func, number, shape, string } = PropTypes;

GenericCoverageCheckForm.propTypes = {
  allianceLevelLandingPage: bool,
  allowUnemployedOption: bool,
  onCoverageCheckResult: func.isRequired,
  orangeHealthInsurerOrganization: shape({
    name: string.isRequired,
    orange_database_id: number.isRequired,
  }),
};

export default GenericCoverageCheckForm;

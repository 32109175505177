import superagent from 'superagent';

const VisitorEvent = {
  create: (data, handler) => {
    superagent
      .post(`/api/v1/visitor_events`)
      .send(data)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .set('Accept', 'application/json')
      .end(handler);
  },
};

export default VisitorEvent;

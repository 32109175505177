import Modal from '@omada/react-therapy/src/modals/Modal';
import ModalContent from '@omada/react-therapy/src/modals/ModalContent';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { parseQueryParams } from 'utils/parse_query_params';

function ChoiceModal({
  uses_choice_modal,
  choice_modal_heading,
  choice_modal_explanation,
  choice_modal_top_text,
  choice_modal_middle_text,
  choice_modal_middle_url,
  choice_modal_middle_opportunity,
  choice_modal_middle_is_type2,
  choice_modal_bottom_is_type2,
  choice_modal_bottom_opportunity,
  choice_modal_bottom_url,
  choice_modal_bottom_text,
  choice_modal_submit_button_text,
  choice_modal_top_url,
  choice_modal_top_opportunity,
  choice_modal_top_is_type2,
  mixpanel,
}) {
  if (!uses_choice_modal) return null;

  const [isOpen, setIsOpen] = useState(false);
  const [type2Selected, setType2Selected] = useState(false);
  const [buttonTarget, setButtonTarget] = useState(null);
  const [opportunityId, setOpportunityId] = useState(null);
  const [showMissingOptionError, setShowMissingOptionError] = useState(false);

  const handleTriggerClick = (event) => {
    event.preventDefault();
    setIsOpen(true);
  };

  useEffect(() => {
    const queryParams = parseQueryParams(window.location.search);
    const shouldOpen = 'show-choice' in queryParams;
    setIsOpen(shouldOpen);

    const triggers = document.querySelectorAll('.js-choice-modal-trigger');
    Array.from(triggers).forEach((trigger) => trigger.addEventListener('click', handleTriggerClick));
  }, []);

  const handleTopButtonClick = () => {
    setButtonTarget(choice_modal_top_url);
    setOpportunityId(choice_modal_top_opportunity);
    setType2Selected(choice_modal_top_is_type2);
  };

  const handleMiddleButtonClick = () => {
    setButtonTarget(choice_modal_middle_url);
    setOpportunityId(choice_modal_middle_opportunity);
    setType2Selected(choice_modal_middle_is_type2);
  };

  const handleBottomButtonClick = () => {
    setButtonTarget(choice_modal_bottom_url);
    setOpportunityId(choice_modal_bottom_opportunity);
    setType2Selected(choice_modal_bottom_is_type2);
  };

  const renderMissingOptionError = () => {
    if (showMissingOptionError) {
      return <div className='u-4mt--s t-c--error-5'>Sorry, you must select an option before continuing.</div>;
    }
    return null;
  };

  const renderOptionalMiddleButton = () => {
    if (choice_modal_middle_text) {
      return (
        <div className='u-4mb--s'>
          <input
            onChange={handleMiddleButtonClick}
            name='choice-modal-target'
            type='radio'
            id='middleRadioButton'
            className='c-form__radio'
          />
          <label className='c-form__radio-label' htmlFor='middleRadioButton'>
            {choice_modal_middle_text}
          </label>
        </div>
      );
    }
    return null;
  };

  const redirectToTarget = (event) => {
    event.preventDefault();
    if (buttonTarget) {
      if (opportunityId) {
        mixpanel.register({ opportunity_id: opportunityId });
      }
      if (buttonTarget === 'show_risk_screener') {
        mixpanel.track('risk_screener_option_clicked_redirecting_to_program_app_instead');
      } else {
        if (type2Selected) {
          mixpanel.track('type2_selected');
        }
        window.location = buttonTarget;
      }
    } else {
      setShowMissingOptionError(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} a11yLabel='Message dialog'>
      <ModalContent>
        <h3
          id='choice-modal-heading'
          className='qa-choice-modal-heading'
          dangerouslySetInnerHTML={{ __html: choice_modal_heading }}
        />
        <br />
        <div className='qa-choice-modal-explanation' dangerouslySetInnerHTML={{ __html: choice_modal_explanation }} />
        {renderMissingOptionError()}
        <form role='group' aria-labelledby='choice-modal-heading' className='u-4mt--m qa-choice-modal--form'>
          <div className='u-4mb--s'>
            <input
              onChange={handleTopButtonClick}
              name='choice-modal-target'
              type='radio'
              id='topRadioButton'
              className='c-form__radio'
            />
            <label className='c-form__radio-label' htmlFor='topRadioButton'>
              {choice_modal_top_text}
            </label>
          </div>
          {renderOptionalMiddleButton()}
          <div>
            <input
              onChange={handleBottomButtonClick}
              name='choice-modal-target'
              type='radio'
              className='c-form__radio'
              id='bottomRadioButton'
            />
            <label className='c-form__radio-label' htmlFor='bottomRadioButton'>
              {choice_modal_bottom_text}
            </label>
          </div>
          <div className='u-4mt--m u-4mb--m u-text--c'>
            <button className='c-btn--primary' onClick={redirectToTarget}>
              {choice_modal_submit_button_text}
            </button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  );
}

const { string, object, bool, array, oneOfType } = PropTypes;

ChoiceModal.propTypes = {
  uses_choice_modal: bool,
  choice_modal_heading: string,
  choice_modal_explanation: string,
  choice_modal_top_text: string,
  choice_modal_bottom_text: string,
  choice_modal_submit_button_text: string,
  choice_modal_middle_text: string,
  choice_modal_bottom_url: string,
  choice_modal_bottom_opportunity: string,
  choice_modal_bottom_is_type2: bool,
  choice_modal_top_url: string,
  choice_modal_top_opportunity: string,
  choice_modal_top_is_type2: bool,
  choice_modal_middle_url: string,
  choice_modal_middle_opportunity: string,
  choice_modal_middle_is_type2: bool,
  mixpanel: oneOfType([ object, array]),
};

export default ChoiceModal;

import React from 'react';

const MagnifyingGlass = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='10.5' cy='10.5' r='8.5' stroke='#868E96' strokeWidth='2' />
      <path d='M17 17L22 22' stroke='#868E96' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
};

export default MagnifyingGlass;

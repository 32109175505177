import { debounce } from 'lodash/function';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';

import Info from '../landing_page/icons/info';
import MagnifyingGlass from '../landing_page/icons/magnifying-glass';
import SpanWithTooltip from '../shared/span_with_tooltip';

function Typeahead(props) {
  const {
    containerClassNames,
    errors,
    fieldName,
    initialInputValue,
    label,
    onChange,
    onLoadOptions,
    searchOptions,
    onRadioChange,
    placeholder,
    radioText,
    radioValue,
    showRadio,
    tooltipContent,
  } = props;
  const typeaheadId = `${fieldName}-typeahead`;
  const radioId = `${typeaheadId}-radio`;
  const labelId = `${fieldName}-label`;
  const hasErrors = !!errors[fieldName];

  const [searchDisplay, setSearchDisplay] = useState(!initialInputValue?.label);
  const [inputVal, setInputVal] = useState(initialInputValue);
  const [selectRef, setSelectRef] = useState(null);
  const debouncedLoadOptions = useRef(
    debounce((inputValue, callback) => {
      const inputIsMerelyWhitespace = inputValue && !inputValue.trim();
      if (inputIsMerelyWhitespace) return;

      onLoadOptions(inputValue, callback, searchOptions);
    }, 500),
  ).current;

  const selectStyles = () => ({
    control: (provided, state) => {
      return {
        ...provided,
        borderColor: state.isFocused ? '#0BAEA2' : '#b2b2b2',
        backgroundColor: 'white',
        padding: 3,
        boxShadow: 0,
        cursor: searchDisplay && 'pointer',
      };
    },
    input: (provided) => {
      return {
        ...provided,
        paddingLeft: searchDisplay ? 28 : 0,
      };
    },
    option: (provided, state) => ({
      ...provided,
      padding: 18,
      borderTop: '1px solid #DEE2E6',
      backgroundColor: state.isFocused ? '#E6F9F9' : 'white',
      color: state.isFocused && '#007383',
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: 8,
      paddingLeft: 28,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#383838',
    }),
    indicatorSeparator: () => null,
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 262,
      overflow: 'scroll',
    }),
  });

  const handleInputChange = (newValue, actionObj) => {
    switch (actionObj.action) {
      case 'input-change':
        onChange(newValue);
        setInputVal({ label: newValue, value: 'user-entered' });
        break;
      case 'select-option':
        onChange(newValue);
        setInputVal(newValue);
        setSearchDisplay(false);
        break;
      case 'input-blur':
      case 'menu-close':
        if (inputVal && inputVal.label.length > 0) {
          setSearchDisplay(false);
        }
        break;
      default:
        break;
    }
  };

  const handleFocus = () => {
    setSearchDisplay(true);
  };

  const handleEditClick = () => {
    setSearchDisplay(true);
    setInputVal(null);
    onChange('');
    setTimeout(() => {
      selectRef.focus();
    }, 100);
  };

  const handleRadioChange = () => {
    setInputVal(null);
    setSearchDisplay(true);
    onRadioChange();
  };

  const renderEditEntry = () => {
    const handleEditSelect = (event) => {
      if (event.key === 'Enter') {
        handleEditClick();
      }
    };

    return (
      <div
        className='u-pos--a typeahead__form-edit-typeahead t-c--teal-5 u-4mr--xs qa-edit'
        onClick={handleEditClick}
        onKeyPress={handleEditSelect}
        role='button'
        tabIndex='0'
      >
        Edit
      </div>
    );
  };

  const renderOptionLabel = ({ label: optionLabel }) => {
    const userInput = inputVal.label;
    if (!searchDisplay) {
      return <div>{userInput}</div>;
    }

    const indexOfUserEntry = optionLabel.toLowerCase().indexOf(userInput.toLowerCase());
    if (indexOfUserEntry === -1) {
      return <div>{optionLabel}</div>;
    }

    const beginningString = optionLabel.substring(0, indexOfUserEntry);
    const matchingString = optionLabel.substring(indexOfUserEntry, indexOfUserEntry + userInput.length);
    const endString = optionLabel.substring(indexOfUserEntry + userInput.length);
    return (
      <div className='qa-typeahead-option'>
        {beginningString}
        <span className='u-text--b'>{matchingString}</span>
        {endString}
      </div>
    );
  };

  return (
    <div className={containerClassNames}>
      <div className='u-flex u-align-items-center u-4mb--xxs'>
        <label className='c-form__label form__label t-c--dark u-4mr--xxs' htmlFor={typeaheadId} id={labelId}>
          {label}
        </label>
        <SpanWithTooltip
          id={`${typeaheadId}-tooltip`}
          tooltipContent={tooltipContent}
          placement='right'
        >
          <Info />
        </SpanWithTooltip>
      </div>
      <AsyncSelect
        aria-labelledby={labelId}
        blurInputOnSelect
        isDisabled={!searchDisplay}
        ref={(ref) => {
          setSelectRef(ref);
        }}
        value={inputVal}
        inputId={typeaheadId}
        formatOptionLabel={renderOptionLabel}
        onFocus={handleFocus}
        onChange={(inputValue, action) => handleInputChange(inputValue, action)} // when user selects from dropdown
        onInputChange={(inputValue, action) => handleInputChange(inputValue, action)} // when user types in input
        openMenuOnClick={false}
        loadOptions={(inputValue, callback) => debouncedLoadOptions(inputValue, callback)}
        noOptionsMessage={() => null}
        placeholder={placeholder}
        components={{
          DropdownIndicator: () => null,
        }}
        styles={selectStyles(searchDisplay)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 3,
          colors: {
            ...theme.colors,
            primary: '#0BAEA2',
          },
        })}
      />
      {searchDisplay && (
        <div className='u-pos--a typeahead__form-magnifying-glass'>
          <MagnifyingGlass />
        </div>
      )}
      {!searchDisplay && renderEditEntry()}
      {showRadio && (
        <input
          type='radio'
          className='c-form__radio typeahead__radio-input qa-typeahead-radio'
          id={radioId}
          onChange={handleRadioChange}
          checked={radioValue}
        />
      )}
      {showRadio && (
        <label className='c-form__radio-label typeahead__radio-label u-4pl--xl u-4mt--s u-font--main' htmlFor={radioId}>
          {radioText}
        </label>
      )}
      {hasErrors && (
        <div className={`c-form__field-error u-4mt--s qa-${fieldName}-error`}>{errors[fieldName].join(', ')}</div>
      )}
    </div>
  );
}

const { bool, func, object, shape, string } = PropTypes;

Typeahead.defaultProps = {
  showRadio: false,
  radioValue: false,
  searchOptions: {},
};

Typeahead.propTypes = {
  containerClassNames: string.isRequired,
  errors: object.isRequired,
  fieldName: string.isRequired,
  initialInputValue: shape({
    label: string.isRequired,
    value: string,
  }),
  label: string.isRequired,
  onChange: func.isRequired,
  onLoadOptions: func.isRequired,
  searchOptions: object,
  onRadioChange: func,
  placeholder: string,
  radioValue: bool,
  showRadio: bool,
  tooltipContent: string.isRequired,
};

export default Typeahead;

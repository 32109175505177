import { debounce } from 'lodash';
import { intersection } from 'lodash/array';
import React, { useState, useEffect } from 'react';

import {
  combineDiabetesConditions,
  getConditionsWithDevices,
  programsWithDevices,
} from '../../utils/possible_conditions';
import windowIsMobileSize from '../../utils/windowIsMobileSize';

import DevicesDesktopSlider from './devices_desktop_slider';
import DevicesMobileSlider from './devices_mobile_slider';

const programInfos = {
  prevention: {
    title: 'Wireless smart scale',
    condition: 'prevention',
    img: 'design_2021/Landing-Page-Device-Image_scale.png',
    imageAlt: 'scale for the program',
    bodyText: 'Your scale connects to your mobile device right away—no setup required, yours to keep.',
    bulletTextList: [
      'Instantly syncs to your account',
      'Track your weight loss over time',
      'Get personalized feedback',
    ],
  },
  hypertension: {
    title: 'Blood pressure monitor',
    condition: 'hypertension',
    img: 'design_2021/device-bpm.png',
    imageAlt: 'blood pressure monitor',
    bodyText: 'Comes connected to your account, no setup required.',
    bulletTextList: ['Seamless tracking', 'Personalized feedback', 'Yours to keep for good'],
  },
  diabetes: {
    title: 'Blood glucose meter',
    condition: 'diabetes',
    img: 'design_2021/device-bgm.png',
    imageAlt: 'blood glucose meter',
    bodyText: 'Comes connected to your account. No setup required, yours to keep.',
    bulletTextList: ['Unlimited test strips', 'Seamless tracking', 'Personalized feedback'],
  },
};

function Devices() {
  const { possible_conditions_at_entry } = window.dataFromRuby;
  if (
    possible_conditions_at_entry &&
    intersection(possible_conditions_at_entry, ['prevention', 'type1_diabetes', 'type2_diabetes', 'hypertension'])
      .length === 0
  ) {
    return null;
  }
  const [onMobileScreen, setOnMobileScreen] = useState(windowIsMobileSize(1024));

  const devicesSectionConfig = {
    header: 'Track your progress with ease',
    subHeader: 'Based on your needs, we’ll send you smart health devices to track how you eat, sleep, move, and feel.',
  };

  const retrieveProgramInfo = () => {
    const conditionsToPresent = getConditionsWithDevices(possible_conditions_at_entry);
    return conditionsToPresent.filter(Boolean).map((condition) => programInfos[condition]);
  };

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => setOnMobileScreen(windowIsMobileSize()), 200),
    );
  }, []);

  const programInfoToPresent = retrieveProgramInfo();

  return (
    <div className='ev-device-at-no-cost qa-devices'>
      <h1 className='ev-device-at-no-cost__header qa-device-at-no-cost-header'>{devicesSectionConfig.header}</h1>
      <p className='ev-device-at-no-cost__body qa-device-at-no-cost-body'>{devicesSectionConfig.subHeader}</p>
      {onMobileScreen ? (
        <DevicesMobileSlider programInfoToPresent={programInfoToPresent} />
      ) : (
        <DevicesDesktopSlider programInfoToPresent={programInfoToPresent} />
      )}
    </div>
  );
}

export default Devices;

import { isEqual } from 'lodash';
import { intersection } from 'lodash/array';

const conditionSortOrder = {
  prevention: 1,
  musculoskeletal: 2,
  hypertension: 3,
  diabetes: 4,
};

const singleConditionLandingPagesToDevicesToPresent = {
  prevention: ['prevention'],
  hypertension: ['prevention', 'hypertension'],
  diabetes: ['prevention', 'diabetes'],
};

export const programsWithDevices = ['prevention', 'hypertension', 'diabetes'];

export const combineDiabetesConditions = (possible_conditions_at_entry) => {
  let hasDiabetes = false;
  const filteredList = (possible_conditions_at_entry || []).filter((condition) => {
    if (condition.indexOf('diabetes') > 0) {
      hasDiabetes = true;
      return false;
    }
    return true;
  });

  if (hasDiabetes) {
    filteredList.push('diabetes');
  }

  return filteredList;
};

export const getDominantCondition = (possible_conditions) => {
  let dominant_condition = 'all';
  const filteredConditions = combineDiabetesConditions(possible_conditions).sort();
  if (isEqual(filteredConditions, ['diabetes']) || isEqual(filteredConditions, ['prevention', 'diabetes'].sort())) {
    dominant_condition = 'diabetes';
  } else if (
    isEqual(filteredConditions, ['hypertension']) ||
    isEqual(filteredConditions, ['prevention', 'hypertension'].sort())
  ) {
    dominant_condition = 'hypertension';
  } else if (isEqual(filteredConditions, ['prevention'])) {
    dominant_condition = 'prevention';
  }

  return dominant_condition;
};

export const conditionCustomCompare = (condition1, condition2) => {
  const condition1Value = conditionSortOrder[condition1] ? conditionSortOrder[condition1] : 0;
  const condition2Value = conditionSortOrder[condition2] ? conditionSortOrder[condition2] : 0;

  return condition1Value - condition2Value;
};

export const getConditionsWithDevices = (possible_conditions) => {
  if (!possible_conditions || !possible_conditions.length) {
    return ['prevention'];
  }

  const possibleConditionsCombined = combineDiabetesConditions(possible_conditions);

  if (possibleConditionsCombined.length === 1) {
    return singleConditionLandingPagesToDevicesToPresent[possibleConditionsCombined[0]] || [];
  }

  return intersection(programsWithDevices, possibleConditionsCombined);
};

export const hasNonfreeProgram = (possible_conditions) => {
  if (!possible_conditions || !possible_conditions.length) {
    return false;
  }

  if (possible_conditions.includes('musculoskeletal')) {
    return true;
  }

  return false;
};

export const hasDiabetes = (possible_conditions) => {
  if (!possible_conditions || !possible_conditions.length) {
    return false;
  }

  if (combineDiabetesConditions(possible_conditions).includes('diabetes')) {
    return true;
  }

  return false;
};

export const hasHypertension = (possible_conditions) => {
  if (!possible_conditions || !possible_conditions.length) {
    return false;
  }

  if (possible_conditions.includes('hypertension')) {
    return true;
  }

  return false;
};

export const hasDiabetesOrHypertension = (possible_conditions) => {
  return hasDiabetes(possible_conditions) || hasHypertension(possible_conditions);
};

export const hasPreventionOnly = (possible_conditions) => {
  return !possible_conditions || isEqual(possible_conditions, []) || isEqual(possible_conditions, ['prevention']);
};

export const hasMskOnly = (possible_conditions) => {
  return possible_conditions && isEqual(possible_conditions, ['musculoskeletal']);
};

export default getDominantCondition;

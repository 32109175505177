import PropTypes from 'prop-types';
import React from 'react';

const FOCUSABLE_ELEMENTS = ['a', 'button', 'input', 'select', 'textarea'];

/**
 * Moves focus to child element when first rendered.
 *
 * Only one element (HTML or custom React component) as a direct child is allowed.
 * If child is a custom component, it must properly handle the tabIndex prop and implement a focus()
 * method.
 */
class Autofocus extends React.Component {
  componentDidMount() {
    this.el.focus();
  }

  setEl = (el) => {
    this.el = el;
  };

  render() {
    const { children } = this.props;
    const additionalProps = { ref: this.setEl };

    if (FOCUSABLE_ELEMENTS.indexOf(children.type) === -1) {
      additionalProps.tabIndex = -1;
    }

    return React.cloneElement(children, additionalProps);
  }
}

const { element } = PropTypes;

Autofocus.propTypes = {
  children: element.isRequired,
};

export default Autofocus;

import React from 'react';

function Blob1() {
  return (
    <svg role='presentation' width='259' height='420' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M234.266 221.271C320.143 36.961-57.444-36.916-109.461 43.843c-52.016 80.759-52.377 227.542 75.36 319.582 128.254 92.411 182.49 42.156 268.367-142.154z'
        stroke='#F5FAFA'
        strokeWidth='23'
      />
    </svg>
  );
}

export default Blob1;

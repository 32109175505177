import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loaders';

import Autofocus from '../autofocus';

const LoadingSquare = ({ loadingMessage, gifClasses }) => {
  return (
    <div className='qa-loading-square'>
      <Autofocus>
        <p className='gcc__form-superheading'>{loadingMessage}</p>
      </Autofocus>
      <Loader type='ball-grid-pulse' color='#26A65B' role='img' aria-label='Loading GIF' active innerClassName={gifClasses} />
    </div>
  );
};

LoadingSquare.propTypes = {
  loadingMessage: PropTypes.string,
  gifClasses: PropTypes.string,
};

LoadingSquare.defaultProps = {
  loadingMessage: 'Calculating your risk...',
};

export default LoadingSquare;

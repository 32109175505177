import PropTypes from 'prop-types';
import React from 'react';

import OmadaLogo from './omada_logo';

import HeaderLogo from 'components/landing_page/components/header_logo';

function FullScreenModalHeader({ ariaLabel, closeModal, logo, isCigna }) {
  return (
    <div>
      <a
        className='full-screen-modal__close t-c--dark-3 u-text--uc qa-close-modal-link'
        href='#'
        onClick={closeModal}
        aria-label={ariaLabel}
      >
        Close ✕
      </a>

      <a
        className='full-screen-modal__back-to-landing-page-link qa-close-modal-logo'
        href='#'
        onClick={closeModal}
        aria-label={`Omada Logo - ${ariaLabel}`}
      >
        {logo ? <HeaderLogo logo={logo} /> : <OmadaLogo isCigna={isCigna} />}
      </a>
    </div>
  );
}

const { bool, func, string } = PropTypes;

FullScreenModalHeader.propTypes = {
  ariaLabel: string.isRequired,
  closeModal: func.isRequired,
  isCigna: bool,
  logo: string,
};

export default FullScreenModalHeader;

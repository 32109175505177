import PropTypes from 'prop-types';
import React from 'react';

function Anchor({ anchorHref, anchorText, ariaLabel, children, classNames, event, section, type, role }) {
  const { design_type, force_landing_page_design } = window.dataFromRuby;
  const displayedDesign = force_landing_page_design || design_type;
  const handleClick = () => {
    mixpanel.track('click_landing_page_non_cta', {
      action: anchorHref,
      cta_class: event,
      design_type: displayedDesign,
      label: anchorText,
      section,
      type,
    });
  };

  return (
    <a
      aria-label={ariaLabel}
      className={classNames}
      event={event}
      href={anchorHref}
      onClick={handleClick}
      section={section}
      role={role}
    >
      {anchorText || children}
    </a>
  );
}

const { string, node, oneOf } = PropTypes;

Anchor.propTypes = {
  anchorHref: string,
  anchorText: string,
  ariaLabel: string.isRequired,
  children: node,
  classNames: string,
  type: oneOf(['button', 'card', 'icon', 'link', 'modal']),
  section: string.isRequired,
  event: oneOf(['info', 'login', 'video']).isRequired,
  role: string,
};

Anchor.defaultProps = {
  anchorText: '',
  type: 'link',
};

export default Anchor;

import React from 'react';

const TextBubbleCircleIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="black"/>
      <g clipPath="url(#text-bubble-circle-icon-clip-path)">
        <rect width="24" height="24" transform="translate(8 8)" fill="black"/>
        <path
          d="M29.4319 9.125H14.6001C13.4227 9.125 12.459 10.1539 12.459 11.3996V20.5863C12.459 21.832 13.4227 22.8704 14.6001 22.8704H21.0532C21.1144 22.8685 21.1753 22.8798 21.2318 22.9037C21.2882 22.9276 21.3388 22.9635 21.38 23.0088L25.4052 27.6331L26.3767 23.1996C26.3984 23.1043 26.4523 23.0195 26.5294 22.9595C26.6065 22.8995 26.702 22.868 26.7997 22.8704H29.4319C30.6094 22.8704 31.5618 21.832 31.5618 20.5863V11.3996C31.5618 10.1539 30.6094 9.125 29.4319 9.125ZM26.2585 16.7997C26.2589 16.9155 26.2137 17.0268 26.1327 17.1097C26.0517 17.1925 25.9414 17.2402 25.8256 17.2424H23.6068V19.4513C23.6072 19.5672 23.562 19.6785 23.481 19.7613C23.4 19.8442 23.2897 19.8918 23.1739 19.8941H21.0093C20.8935 19.8918 20.7831 19.8442 20.7021 19.7613C20.6212 19.6785 20.576 19.5672 20.5763 19.4513V17.2424H18.3576C18.2418 17.2402 18.1315 17.1925 18.0505 17.1097C17.9695 17.0268 17.9243 16.9155 17.9247 16.7997V14.635C17.9249 14.5787 17.9363 14.5229 17.9582 14.4711C17.9802 14.4192 18.0122 14.3722 18.0525 14.3328C18.0928 14.2934 18.1405 14.2624 18.1929 14.2417C18.2453 14.2209 18.3013 14.2108 18.3576 14.2119H20.5763V11.9833C20.5765 11.927 20.588 11.8713 20.6099 11.8194C20.6318 11.7675 20.6639 11.7205 20.7042 11.6811C20.7445 11.6417 20.7922 11.6108 20.8446 11.59C20.897 11.5692 20.9529 11.5591 21.0093 11.5602H23.1739C23.2302 11.5591 23.2862 11.5692 23.3386 11.59C23.391 11.6108 23.4387 11.6417 23.479 11.6811C23.5193 11.7205 23.5513 11.7675 23.5733 11.8194C23.5952 11.8713 23.6066 11.927 23.6068 11.9833V14.2119H25.8256C25.8819 14.2108 25.9379 14.2209 25.9903 14.2417C26.0426 14.2624 26.0904 14.2934 26.1307 14.3328C26.171 14.3722 26.203 14.4192 26.225 14.4711C26.2469 14.5229 26.2583 14.5787 26.2585 14.635V16.7997Z"
          fill="white"
        />
        <path
          d="M22.7395 14.6347V12.4258H21.4407V14.6347C21.4411 14.7506 21.3959 14.8619 21.3149 14.9448C21.2339 15.0276 21.1236 15.0752 21.0078 15.0775H18.7891V16.3762H21.0078C21.0641 16.3751 21.1201 16.3853 21.1725 16.406C21.2249 16.4268 21.2726 16.4577 21.3129 16.4971C21.3532 16.5365 21.3852 16.5835 21.4072 16.6354C21.4291 16.6873 21.4405 16.743 21.4407 16.7994V19.0279H22.7395V16.7994C22.7397 16.743 22.7511 16.6873 22.7731 16.6354C22.795 16.5835 22.8271 16.5365 22.8674 16.4971C22.9076 16.4577 22.9554 16.4268 23.0078 16.406C23.0601 16.3853 23.1161 16.3751 23.1724 16.3762H25.3912V15.0775H23.1724C23.0566 15.0752 22.9463 15.0276 22.8653 14.9448C22.7843 14.8619 22.7391 14.7506 22.7395 14.6347Z"
          fill="black"
        />
        <path
          d="M10.5223 28.2824C10.6203 28.2807 10.7158 28.3128 10.7929 28.3733C10.8699 28.4338 10.9237 28.519 10.9452 28.6146L11.4681 31.0004L13.7275 28.4222C13.7686 28.3768 13.819 28.3409 13.8752 28.3167C13.9314 28.2926 13.9922 28.2809 14.0534 28.2824H18.291C18.7604 28.2824 19.1684 27.8541 19.1684 27.3506V23.7367H14.5992C12.9443 23.7367 11.5922 22.3098 11.5922 20.5867V20.165H8.59583C8.12648 20.165 7.75 20.5878 7.75 21.0913V27.3506C7.75 27.8541 8.12648 28.2824 8.59583 28.2824H10.5223Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="text-bubble-circle-icon-clip-path">
          <rect width="24" height="24" fill="white" transform="translate(8 8)"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default TextBubbleCircleIcon;

import superagent from 'superagent';

class Employer {
  static search(contains, handler) {
    superagent
      .post('/employers/search')
      .send({ contains })
      .set('Accept', 'application/json')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .end(handler);
  }
}

export default Employer;

import React from 'react';

const HandWithHeartIcon = () => {
  return (
    <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='#fff' />
      <path fill='#fff' d='M8 8h24v24H8z' />
      <path
        d='M13.173 12.78c.475-2.316 2.037-3.767 4.102-3.767 1.172 0 2.428.474 3.711 1.45a.462.462 0 0 0 .53 0c1.256-.92 2.54-1.422 3.683-1.422 2.065 0 3.628 1.423 4.074 3.71.307 1.619-.028 3.433-.948 5.079-1.34 1.618-3.153 3.795-3.572 4.297l-4.576 1.088-1.032-1.144 3.404-1.395c.948-.363 1.423-1.423 1.116-2.4-.251-.92-1.312-1.506-2.288-1.227l-6.725 1.674c-1.311-1.87-1.841-4.018-1.479-5.943zm18.193 6.278l-5.274 6.557a1.49 1.49 0 0 1-.948.53L15.322 27.4a.665.665 0 0 0-.307.167L12.448 31 8.15 26.73l5.72-6.64a1.84 1.84 0 0 1 .781-.446l6.948-1.758c.53-.14 1.088.167 1.256.67.167.53-.084 1.116-.614 1.31l-3.963 1.647a.402.402 0 0 0-.25.307c-.029.14 0 .279.111.39l1.618 1.814a.486.486 0 0 0 .419.14l4.967-1.172a1.13 1.13 0 0 0 .25-.14s2.15-2.595 3.712-4.464c0 0 0-.028.028-.028.195-.223.362-.446.53-.642.167-.223.446-.362.725-.39.28-.028.558.056.782.223l.027.028c.447.363.53 1.004.168 1.479z'
        fill='#000'
      />
    </svg>
  );
};

export default HandWithHeartIcon;

const IMAGE_NAME_TO_BACKGROUND_CLASSES = {
  'hero-no-cost-to-you': {
    bgClass: 'ev-bg--hero-with-testimonials',
    bgStaticClass: 'ev-bg-static--hero-with-testimonials',
  },
  'hero-prevention.png': {
    bgClass: 'ev-bg--pvn',
    bgStaticClass: 'ev-bg-static--pvn',
  },
  'hero-hypertension.png': {
    bgClass: 'ev-bg--htn',
    bgStaticClass: 'ev-bg-static--htn',
  },
  'hero-msk-pvn-2021.png': {
    bgClass: 'ev-bg--pvn-msk',
    bgStaticClass: 'ev-bg-static--pvn-msk',
  },
  'hero-msk-dm-maybe-pvn-2021.png': {
    bgClass: 'ev-bg--d',
    bgStaticClass: 'ev-bg-static--d',
  },
  'hero-htn-msk-maybe-pvn-2021.png': {
    bgClass: 'ev-bg--htn',
    bgStaticClass: 'ev-bg-static--htn',
  },
  'hero-dm-htn-msk-maybe-pvn-2021.png': {
    bgClass: 'ev-bg--d',
    bgStaticClass: 'ev-bg-static--d',
  },
  'hero-msk.png': {
    bgClass: 'ev-bg--msk',
    bgStaticClass: 'ev-bg-static--msk',
  },
  'hero-dm-maybe-pvn-2021.png': {
    bgClass: 'ev-bg--d',
    bgStaticClass: 'ev-bg-static--d',
  },
  'hero-htn-dm-maybe-pvn-2021.png': {
    bgClass: 'ev-bg--pvn-msk',
    bgStaticClass: 'ev-bg-static--pvn-msk',
  },
};

export default IMAGE_NAME_TO_BACKGROUND_CLASSES;

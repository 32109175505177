import React from 'react';

const CoachTextBubblesIcon = () => {
  return (
    <svg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='#fff' />
      <g clipPath='url(#coach-text-bubbles-icon-clip-path)'>
        <path fill='#fff' d='M8 8h24v24H8z' />
        <path
          d='M29.43 9.125H14.6c-1.178 0-2.142 1.029-2.142 2.275v9.186c0 1.246.964 2.284 2.141 2.284h6.453a.424.424 0 0 1 .327.139l4.025 4.624.972-4.433a.423.423 0 0 1 .423-.33h2.632c1.177 0 2.13-1.038 2.13-2.284V11.4c0-1.246-.953-2.275-2.13-2.275zM26.259 16.8a.441.441 0 0 1-.433.442h-2.22v2.21a.442.442 0 0 1-.432.442h-2.165a.442.442 0 0 1-.433-.443v-2.209h-2.218a.441.441 0 0 1-.433-.442v-2.165a.423.423 0 0 1 .433-.423h2.218v-2.229a.425.425 0 0 1 .433-.423h2.165a.424.424 0 0 1 .433.423v2.229h2.219a.424.424 0 0 1 .433.423V16.8z'
          fill='#000'
        />
        <path
          d='M22.74 14.635v-2.21h-1.299v2.21a.441.441 0 0 1-.433.443H18.79v1.298h2.218a.426.426 0 0 1 .433.423v2.229h1.299v-2.229a.424.424 0 0 1 .433-.423h2.219v-1.299h-2.22a.442.442 0 0 1-.432-.442zM10.522 28.282a.426.426 0 0 1 .423.332L11.468 31l2.26-2.578a.427.427 0 0 1 .325-.14h4.238c.47 0 .877-.428.877-.932v-3.614H14.6c-1.655 0-3.007-1.426-3.007-3.15v-.421H8.596c-.47 0-.846.423-.846.926v6.26c0 .503.376.931.846.931h1.926z'
          fill='#000'
        />
      </g>
      <defs>
        <clipPath id='coach-text-bubbles-icon-clip-path'>
          <path fill='#fff' transform='translate(8 8)' d='M0 0h24v24H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoachTextBubblesIcon;

import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';

function IntroModal({ isOpen, handleCloseModal }) {
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          border: 'none !important',
          backgroundColor: 'transparent !important',
        },
      }}
      overlayClassName={{
        base: 'testimony-overlay',
        afterOpen: 'testimony-overlay--visible',
        beforeClose: 'testimony-overlay--hidden',
      }}
      shouldCloseOnOverlayClick={false}
    >
      <div className='u-flex u-align-items-start testimony-modal-container'>
        <div className='testimony-modal-responsive'>
          <iframe
            title='Omada Health introduction video'
            data-testid='video-iframe'
            src={window.dataFromRuby.hero_video_url}
            width='980'
            height='550'
            frameBorder='0'
            allowFullScreen
          />
        </div>
        <button
          type='button'
          className='testimony-overlay__close-button'
          onClick={() => {
            handleCloseModal();
          }}
          aria-label='Close video button'
        >
          ✕
        </button>
      </div>
    </ReactModal>
  );
}

const { bool, func } = PropTypes;

IntroModal.propTypes = {
  isOpen: bool.isRequired,
  handleCloseModal: func.isRequired,
};

export default IntroModal;

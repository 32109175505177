import PropTypes from 'prop-types';
import React from 'react';

function Covered({ v2RegistrationUrl }) {
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;
  return (
    <div className='gcc__results-container gcc__results-container--horizontal qa-gcc-covered-result'>
      <div className='left'>
        <p className='gcc__results-heading' role='heading' aria-level='2'>
          Great news&mdash; looks like you may be covered!
        </p>
        <p className='gcc__results-content'>
          Based on the information you entered, your employer or health insurer likely offers Omada as a covered health
          benefit. If you’re eligible, you can join this groundbreaking online program at no cost to you.
        </p>
        <a className='c-btn--primary gcc__results-button u-4pt--s qa-gcc-results-button' href={v2RegistrationUrl}>
          Get Started
        </a>
      </div>
      <div className='right'>
        <img alt='covered' role='presentation' src={`${staticAssetsUrlBase}/generic_coverage_check/covered.png`} />
      </div>
    </div>
  );
}

Covered.propTypes = {
  v2RegistrationUrl: PropTypes.string.isRequired,
};

export default Covered;

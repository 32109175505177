import React, { Component } from 'react';
import RightArrow from "../../../shared/right_arrow";

class RestartTheCoverageCheckLink extends Component {
  handleLinkClick = (event) => {
    event.preventDefault();
    mixpanel.track('restarted_generic_coverage_check');
    this.props.onRestartCoverageCheck();
  };

  render() {
    return (
      <p>
        <a className='c-text-action--primary u-p--n' href='#' onClick={this.handleLinkClick}>
          TRY AGAIN <RightArrow />
        </a>
      </p>
    );
  }
}

export default RestartTheCoverageCheckLink;

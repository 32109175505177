import React from 'react';

import NavbarCircle from '../icons/navbar_circle';

const GenericCoverageCheckNavbar = () => {
  const renderCoverageTab = () => {
    return (
      <div className='u-4pt--s u-4pb--s u-heading--caps u-flex--1 t-bg--main qa-nav-tab'>
        <div className='gcc__form-navbar-item u-m--a u-flex u-align-items-center t-c--white'>
          <NavbarCircle active />
          <span className='u-4ml--xxs qa-nav-tab-label'>Coverage</span>
        </div>
      </div>
    );
  };

  const renderEligibilityTab = () => {
    return (
      <div className='u-4pt--s u-4pb--s u-heading--caps u-border--b u-border--t t-bc--grey u-flex--1 t-bg--grey-1 qa-nav-tab'>
        <div className='gcc__form-navbar-item u-m--a u-flex u-align-items-center t-c--grey-4'>
          <NavbarCircle active={false} />
          <span className='u-4ml--xxs qa-nav-tab-label'>Eligibility</span>
        </div>
      </div>
    );
  };

  return (
    <div className='u-flex u-justify-content-around'>
      {renderCoverageTab()}
      {renderEligibilityTab()}
    </div>
  );
};

export default GenericCoverageCheckNavbar;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { setURLParam, removeURLParam, getURLParam } from '../../../../utils/url_param_helpers';
import IntroModal from '../../intro_modal';
import CtaButton from '../cta_button';

import isEnterKey from 'helpers/keypress';

function HeroSection({
  config: { headerText, bodyText, videoText, imageUrl, slideUrl, ctaText, bgClass, bgStaticClass },
  forceConfig,
}) {
  const { hero_description, hero_sub_description } = window.dataFromRuby;
  const showVideo = window.dataFromRuby.show_video;
  const load_page_with_video_open = getURLParam('open_video') === 'true';
  const [isModalOpen, setIsModalOpen] = useState(load_page_with_video_open);
  const heroText = forceConfig ? headerText : hero_description || headerText;
  const heroSubText = forceConfig ? bodyText : hero_sub_description || bodyText;
  const leftColumnClass = `ev-hero-section-v2__left-side ${bgClass}`;
  const rightColumnClass = `ev-hero-section-v2__right-side ${bgStaticClass}`;
  const openVideoModal = () => {
    setIsModalOpen(true);
    setURLParam('open_video', 'true');
  };
  const closeVideoModal = () => {
    setIsModalOpen(false);
    removeURLParam('open_video');
  };

  return (
    <div className='hero-section__container qa-hero-section'>
      <div className='ev-hero-section-v2'>
        <div className={leftColumnClass}>
          <h1
            className='ev-hero-section-v2__header qa-hero-header-text'
            data-testid='hero-header'
            dangerouslySetInnerHTML={{ __html: heroText }}
          />
          <p
            className='ev-hero-section-v2__text qa-hero-body-text'
            dangerouslySetInnerHTML={{ __html: heroSubText }}
            data-testid='hero-body'
          />
          <div className='ev-hero-section-v2__button-group'>
            <CtaButton
              classNames='ev-button-primary qa-cta-button'
              ctaText={ctaText}
              forceConfig={forceConfig}
              section='hero'
            />
            {showVideo && (
              <a
                className='ev-text-action-primary ev-play-video qa-hero-video-text'
                onClick={openVideoModal}
                onKeyDown={(e) => {
                  if (isEnterKey(e.key)) {
                    openVideoModal();
                  }
                }}
                section='hero'
                event='video'
                role='button'
                tabIndex='0'
              >
                {videoText}
              </a>
            )}
          </div>
        </div>
        <div className={rightColumnClass}>
          <img
            className='ev-hero-section-v2__hero-image qa-hero-image'
            role='presentation'
            data-testid='qa-hero-image'
            src={imageUrl}
            alt=''
          />
          {slideUrl && <img className='slide-a-tron qa-slide-image' alt='2.1 million pounds lost' src={slideUrl} />}
        </div>
        <IntroModal handleCloseModal={closeVideoModal} isOpen={isModalOpen} />
      </div>
    </div>
  );
}

const { bool, shape, string } = PropTypes;

HeroSection.propTypes = {
  config: shape({
    headerText: string.isRequired,
    bodyText: string.isRequired,
    videoText: string,
    imageUrl: string.isRequired,
    slideUrl: string,
    ctaText: string,
  }),
  forceConfig: bool,
};

export default HeroSection;

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import FullScreenModalHeader from '../../shared/full_screen_modal_header';

import GenericCoverageCheckForm, { SERVER_ERROR } from './generic_coverage_check_form';
import GenericCoverageCheckNavbar from './generic_coverage_check_navbar';
import ComingSoon from './results/coming_soon';
import Covered from './results/covered';
import CurrentlyClosed from './results/currently_closed';
import MaybeCovered from './results/maybe_covered';
import NotCovered from './results/not_covered';
import ServerError from './results/server_error';

export const COVERED = 'covered';
export const MAYBE_COVERED = 'maybe_covered';
export const NOT_COVERED = 'not_covered';
export const COMING_SOON = 'coming_soon';
export const CURRENTLY_CLOSED = 'currently_closed';
export const GENERIC_COVERAGE_CHECK = 'generic_coverage_check';

function GenericCoverageCheckContainer({ closeModal }) {
  const [displayedPage, setDisplayedPage] = useState(GENERIC_COVERAGE_CHECK);
  const [genericCoverageCheckId, setGenericCoverageCheckId] = useState(null);
  const [v2RegistrationUrl, setV2RegistrationUrl] = useState(null);
  const { alliance_level_landing_page, allow_unemployed_option_on_gcc, logo, orange_health_insurer_organization } =
    window.dataFromRuby;

  const handleGenericCoverageCheckResult = (checkResult) => {
    setGenericCoverageCheckId(checkResult.generic_coverage_check_id);
    setV2RegistrationUrl(checkResult.v2_registration_url);
    setDisplayedPage(checkResult.results_page);
  };

  const modalContent = () => {
    switch (displayedPage) {
      case COVERED:
        return <Covered v2RegistrationUrl={v2RegistrationUrl} />;
      case MAYBE_COVERED:
        return <MaybeCovered v2RegistrationUrl={v2RegistrationUrl} />;
      case NOT_COVERED:
        return (
          <NotCovered
            genericCoverageCheckId={genericCoverageCheckId}
            onRestartCoverageCheck={() => setDisplayedPage(GENERIC_COVERAGE_CHECK)}
          />
        );
      case CURRENTLY_CLOSED:
        return <CurrentlyClosed />;
      case COMING_SOON:
        return <ComingSoon />;
      case GENERIC_COVERAGE_CHECK:
        return (
          <GenericCoverageCheckForm
            allianceLevelLandingPage={alliance_level_landing_page}
            allowUnemployedOption={allow_unemployed_option_on_gcc}
            onCoverageCheckResult={handleGenericCoverageCheckResult}
            orangeHealthInsurerOrganization={orange_health_insurer_organization}
          />
        );
      case SERVER_ERROR:
      default:
        return <ServerError />;
    }
  };
  const showNavBar = displayedPage !== NOT_COVERED;
  const formBodyClass = classnames({
    'full-screen-modal__form-body': displayedPage !== NOT_COVERED,
  });

  return (
    <main className='generic-coverage-check-container full-screen-modal-form-container fadeIn'>
      <section className='full-screen-modal__form'>
        <FullScreenModalHeader closeModal={closeModal} ariaLabel='Close coverage check questionnaire' logo={logo} />
        <div className={formBodyClass}>
          {showNavBar && <GenericCoverageCheckNavbar />}
          {modalContent()}
        </div>
      </section>
    </main>
  );
}

GenericCoverageCheckContainer.propTypes = {
  closeModal: PropTypes.func,
};

export default GenericCoverageCheckContainer;

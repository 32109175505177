import { debounce, isEqual } from 'lodash';
import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import keydownEnterHandler from '../../utils/keydown_enter_handler';

import ProgramInfoTile2021 from './program_info_tile_2021';

import ArrowInCircle from 'components/landing_page/icons/arrow_in_circle';
import Blob1 from 'components/landing_page/icons/blob1';
import BonesIcon from 'components/landing_page/icons/bones_icon';
import CoachTextBubblesIcon from 'components/landing_page/icons/coach_text_bubbles_icon';
import HandWithHeartIcon from 'components/landing_page/icons/hand_with_heart_icon';
import HeadWithBeakerIcon from 'components/landing_page/icons/head_with_beaker_icon';
import ProviderIcon from 'components/landing_page/icons/provider_icon';
import ShoeIcon from 'components/landing_page/icons/shoe_icon';
import TextBubbleCircleIcon from 'components/landing_page/icons/text_bubble_circle_icon';
import { combineDiabetesConditions } from 'components/landing_page/utils/possible_conditions';
import sliderSetters from 'components/landing_page/utils/slider_settings';
import windowIsMobileSize from 'components/landing_page/utils/windowIsMobileSize';

const diabetesMultiConditionContent = (includesHypertension) => {
  const hypertensionSubtext = includesHypertension ? ', hypertension specialist,' : '';
  return {
    planForYou: {
      title: 'A plan created for you',
    },
    programHighlight1: {
      subtext: `Your dedicated health coach${hypertensionSubtext} and Certified Diabetes Care and Education Specialist (CDCES) actually gets to know <i>you</i>.`,
    },
    programHighlight2: {
      subtext: 'We’ll show you ways to manage (and even reduce) your medications without hard rules and restrictions.',
    },
  };
};

const includesNonCoreConditions = (conditions) => {
  return conditions.includes('musculoskeletal');
};

const diabetesOnlyContent = {
  planForYou: {
    imageName: 'design_2021/app_home_dm.png',
    title: 'A plan created for you',
  },
  programHighlight1: {
    imageName: 'design_2021/app_coach_dm.png',
    subtext: 'Your Certified Diabetes Care and Education Specialist (CDCES) actually gets to know <i>you</i>.',
  },
  programHighlight2: {
    imageName: 'design_2021/app_home_dm2.png',
    subtext: 'We’ll help you manage your medications and eat for better glucose control.',
  },
};

const preventionOnlyContent = {
  planForYou: {
    title: 'A plan created for you',
    imageName: 'design_2021/app_home_pvn.png',
  },
  programHighlight1: {
    icon: HeadWithBeakerIcon,
    title: 'No more counting calories',
    subtext: 'Try new things you’ll enjoy, rather than avoiding foods you “can’t eat” or things you “shouldn’t do.”',
    imageName: 'design_2021/app_home_pvn2.png',
  },
  programHighlight2: {
    icon: CoachTextBubblesIcon,
    title: 'We’re in your corner',
    subtext:
      'We’ll match you with a dedicated health coach for the support you need to <i>get going</i> and <i>keep going</i>.',
    imageName: 'design_2021/app_coach_pvn.png',
  },
};

const mskOnlyContent = {
  planForYou: {
    icon: BonesIcon,
    title: 'Get ahead of aches and pains',
    subtext:
      'Starting a new activity or just looking for a tuneup? Even if you don’t have a current joint or muscle issue, we’ll help you keep it that way with a self-guided preventative care plan—at no cost to you.',
    imageName: 'design_2021/app_home_msk.png',
  },
  programHighlight1: {
    icon: TextBubbleCircleIcon,
    title: 'Connect face-to-face',
    subtext:
      'If you have recurring pain or an injury, schedule a video chat with a licensed physical therapist within 24 hours. They’ll take time to get to know you and create a treatment plan that fits into your life.',
    imageName: 'design_2021/app_video_visit_msk.png',
  },
  programHighlight2: {
    icon: ShoeIcon,
    title: "Don't just get going—keep going",
    subtext:
      'An enhanced version of what you might experience via in-person physical therapy. It combines symptom relief, therapeutic exercise, patient education, and more with an always-available dedicated PT.',
    imageName: 'design_2021/app_home_msk3.png',
  },
};

const hypertensionOnlyContent = {
  planForYou: {
    imageName: 'design_2021/app_home_htn.png',
    title: 'A plan created for you',
  },
  programHighlight1: {
    imageName: 'design_2021/app_coach_htn.png',
    subtext:
      'We’ll match you with a dedicated health coach and hypertension specialist who actually get to know <i>you</i>.',
  },
  programHighlight2: {
    imageName: 'design_2021/app_home_htn2.png',
    title: 'Better ways to lower your blood pressure',
    subtext:
      'We’ll show you ways to manage your medications and cut your salt intake without giving up foods you love.',
  },
};

const physicianGuidedCareContent = {
  icon: ProviderIcon,
  imageAlt: 'screenshot of Omada mobile app',
  imageName: 'design_2021/physician-guided-care.png',
  name: 'physicianGuidedCare',
  title: 'Care with no appointment needed',
  subtext:
    'Omada makes it easy to connect with a doctor when you need it. No appointments or chatbots—just reliable access to board-certified physicians who care.',
};

const preventionAndHypertensionContent = {
  planForYou: {
    title: 'A plan created for you',
  },
  programHighlight1: {
    title: 'No more counting calories',
    icon: HeadWithBeakerIcon,
    imageName: 'design_2021/app_home_multi2.png',
    subtext: 'Try new things you’ll enjoy, rather than avoiding foods you “can’t eat” or things you “shouldn’t do.”',
  },
  programHighlight2: {
    imageName: 'design_2021/app_coach_multi.png',
    icon: CoachTextBubblesIcon,
    title: 'We’re in your corner',
    subtext:
      'Based on what <i>you</i> need, we’ll match you with a dedicated health coach and hypertension specialist for support.',
  },
};

const headerSubheaderCopy = {
  default: {
    header: "Get healthy, <span class='u-text--i'>your way</span>",
    subheader:
      "Many diets, programs, and apps tell you how to get healthy <span class='u-text--i'>their </span> way. Omada helps you find your way.",
  },
  mskOnly: {
    header: 'Joint & muscle care that adapts to you',
    subheader: '',
  },
};

const determineProductFeatureTilesContent = (possibleConditionsAtEntry, showPhysicianCareContent) => {
  let contentOverrides = {};
  const filteredConditions = combineDiabetesConditions(possibleConditionsAtEntry);
  if (filteredConditions) {
    if (isEqual(filteredConditions, ['diabetes'])) {
      contentOverrides = diabetesOnlyContent;
    } else if (isEqual(filteredConditions, ['hypertension'])) {
      contentOverrides = hypertensionOnlyContent;
    } else if (isEqual(filteredConditions, ['musculoskeletal'])) {
      contentOverrides = mskOnlyContent;
    } else if (filteredConditions.length === 0 || isEqual(filteredConditions, ['prevention'])) {
      contentOverrides = preventionOnlyContent;
    } else if (filteredConditions.length >= 2) {
      if (isEqual(filteredConditions.sort(), ['hypertension', 'prevention'].sort())) {
        contentOverrides = preventionAndHypertensionContent;
      } else if (filteredConditions.includes('diabetes') && !includesNonCoreConditions(filteredConditions)) {
        contentOverrides = diabetesMultiConditionContent(filteredConditions.includes('hypertension'));
      }
    }
  }

  return [
    {
      name: 'planForYou',
      icon: HandWithHeartIcon,
      title: 'A plan built around you',
      subtext: 'We’ll help you build healthy routines around what you love to do, and where you want the most support.',
      imageName: 'design_2021/app_home_multi.png',
      imageAlt: 'screenshot of Omada mobile app',
      ...contentOverrides.planForYou,
    },
    {
      name: 'programHighlight1',
      icon: CoachTextBubblesIcon,
      title: 'Personal support, wherever you are',
      subtext: 'Your dedicated health coach and care team actually get to know you based on your health history.',
      imageName: 'design_2021/app_coach_multi.png',
      imageAlt: 'screenshot of Omada mobile app',
      ...contentOverrides.programHighlight1,
    },
    {
      name: 'programHighlight2',
      icon: HeadWithBeakerIcon,
      title: 'A science-based approach',
      subtext: 'We’ll show you ways you can manage your health without following strict rules and regimens.',
      imageName: 'design_2021/app_home_multi2.png',
      imageAlt: 'screenshot of Omada mobile app',
      ...contentOverrides.programHighlight2,
    },
    ...(showPhysicianCareContent ? [physicianGuidedCareContent] : []),
  ];
};

const determineProductFeatureHeaderContent = (possibleConditionsAtEntry) => {
  const mskOnlyConditionPage = isEqual(possibleConditionsAtEntry, ['musculoskeletal']);

  if (mskOnlyConditionPage) return headerSubheaderCopy.mskOnly;
  return headerSubheaderCopy.default;
};

function ProductFeatures({ possible_conditions_at_entry, show_physician_care_content }) {
  const [activeTileIndex, setActiveTileIndex] = useState(0);
  const [onMobileScreen, setOnMobileScreen] = useState(windowIsMobileSize());
  const productFeatureTilesInfo = determineProductFeatureTilesContent(
    possible_conditions_at_entry,
    show_physician_care_content,
  );
  const headerCopy = determineProductFeatureHeaderContent(possible_conditions_at_entry);
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;

  useEffect(() => {
    window.addEventListener(
      'resize',
      debounce(() => setOnMobileScreen(windowIsMobileSize()), 200),
    );
  }, []);

  useEffect(() => {
    Array.from(document.getElementsByClassName('slick-track')).forEach((track) => track.setAttribute('role', 'list'));
    Array.from(document.getElementsByClassName('slick-slide')).forEach((slide) => {
      slide.setAttribute('role', 'listitem');
    });
  });

  const activeTileName = () => {
    return productFeatureTilesInfo[activeTileIndex].name;
  };

  const onDesktopProgramTileClick = (tileName) => {
    const tile = productFeatureTilesInfo.find((tileConfig) => tileConfig.name === tileName);
    setActiveTileIndex(productFeatureTilesInfo.indexOf(tile));
  };

  const renderProgramTiles = () => {
    if (onMobileScreen) {
      const sliderSettings = sliderSetters({
        arrows: true,
        autoplay: false,
        centerPadding: '12px',
        dotsClass: 'product-features__mobile-dot slick-dots ev-slider-dots',
        infinite: true,
        nextArrow: (
          <ArrowInCircle
            classNames='product-features__arrow right'
            direction='right'
            size='small'
            filterId={`filter_${_uniqueId()}_ddd`}
          />
        ),
        pauseOnFocus: true,
        pauseOnDotsHover: true,
        pauseOnHover: true,
        prevArrow: (
          <ArrowInCircle
            classNames='product-features__arrow left'
            direction='left'
            size='small'
            filterId={`filter_${_uniqueId()}_ddd`}
          />
        ),
      });

      return (
        <div>
          <Slider
            arrows={sliderSettings.arrows}
            autoplay={sliderSettings.autoplay}
            autoplaySpeed={sliderSettings.autoplaySpeed}
            centerMode={sliderSettings.centerMode}
            centerPadding={sliderSettings.centerPadding}
            dots={sliderSettings.dots}
            dotsClass={sliderSettings.dotsClass}
            focusOnSelect={sliderSettings.focusOnSelect}
            infinite={sliderSettings.infinite}
            nextArrow={sliderSettings.nextArrow}
            pauseOnDotsHover={sliderSettings.pauseOnDotsHover}
            pauseOnFocus={sliderSettings.pauseOnFocus}
            pauseOnHover={sliderSettings.pauseOnHover}
            prevArrow={sliderSettings.prevArrow}
            slidesToScroll={sliderSettings.slidesToScroll}
            slidesToShow={sliderSettings.slidesToShow}
          >
            {productFeatureTilesInfo.map((tileConfig) => (
              <div key={tileConfig.name}>
                <ProgramInfoTile2021 tileName={tileConfig.name} tileData={tileConfig} isMobile />
                <div className='u-flex u-justify-content-center u-4pb--xxxl'>
                  <img
                    className='ev-product-features-device-screen'
                    src={`${staticAssetsUrlBase}/landing_page/${tileConfig.imageName}`}
                    alt={tileConfig.imageAlt}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      );
    }
    return (
      <div className='u-flex'>
        <div className='u-flex--1 u-flex u-justify-content-center'>
          <ul className='product-features__tiles-collection'>
            {productFeatureTilesInfo.map((tileConfig) => (
              <ProgramInfoTile2021
                key={tileConfig.name}
                tileName={tileConfig.name}
                tileData={tileConfig}
                activeTileName={activeTileName()}
                onProgramInfoClick={(tileName) => onDesktopProgramTileClick(tileName)}
              />
            ))}
          </ul>
        </div>
        <div className='u-4pt--m u-flex u-flex--1 u-align-items-center u-flex-column u-align-self-center'>
          <div className='u-flex u-justify-content-center'>
            <img
              className='ev-product-features-device-screen'
              src={`${staticAssetsUrlBase}/landing_page/${productFeatureTilesInfo[activeTileIndex].imageName}`}
              alt={productFeatureTilesInfo[activeTileIndex].imageAlt}
            />
          </div>
          <ul className='u-4mt--xxl'>
            {productFeatureTilesInfo.map((tileConfig, index) => (
              <li
                key={`product-feature-dot-${productFeatureTilesInfo[index].name}`}
                className={`product-features__desktop-dot ${activeTileIndex === index && 'active-dot'}`}
              >
                <button
                  className='product-features__button'
                  onClick={() => onDesktopProgramTileClick(productFeatureTilesInfo[index].name)}
                  onKeyDown={(e) =>
                    keydownEnterHandler(e, onDesktopProgramTileClick, productFeatureTilesInfo[index].name)
                  }
                  aria-label={`Highlight product feature ${productFeatureTilesInfo[index].title}`}
                  tabIndex='0'
                  aria-current={activeTileIndex === index}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <div className='t-bg--white ev-subsection-container__desktop u-pos--r'>
      <div className='product-features__blob'>
        <Blob1 />
      </div>
      <div className='ev-subsection-container u-pos--r'>
        <div className='product-features__header u-m--a u-text--c u-4mb--m'>
          <h2
            className='ev-header-base--sm u-4mb--l qa-title'
            dangerouslySetInnerHTML={{ __html: headerCopy.header }}
          />
          <div
            className='ev-body-base--large qa-subheader'
            dangerouslySetInnerHTML={{ __html: headerCopy.subheader }}
          />
        </div>
        {renderProgramTiles()}
      </div>
    </div>
  );
}

const { bool, string, arrayOf } = PropTypes;

ProductFeatures.propTypes = {
  possible_conditions_at_entry: arrayOf(string),
  show_physician_care_content: bool,
};

export default ProductFeatures;

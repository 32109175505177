import PropTypes from 'prop-types';
import React from 'react';

function MaybeCovered({ v2RegistrationUrl }) {
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;
  return (
    <div className='gcc__results-container gcc__results-container--horizontal'>
      <div className='left'>
        <p className='gcc__results-heading'>You may be covered!</p>
        <p className='gcc__results-content'>
          We don’t have quite enough information to confirm your coverage at this point. Before we collect more details
          about your employer/insurer, let’s find out if Omada is a perfect fit for you.
        </p>
        <a className='c-btn--primary gcc__results-button u-4pt--s qa-gcc-results-button' href={v2RegistrationUrl}>
          Get Started
        </a>
      </div>
      <div className='right'>
        <img
          role='presentation'
          alt='covered'
          src={`${staticAssetsUrlBase}/generic_coverage_check/maybe-covered.png`}
        />
      </div>
    </div>
  );
}

MaybeCovered.propTypes = {
  v2RegistrationUrl: PropTypes.string.isRequired,
};

export default MaybeCovered;

import PropTypes from 'prop-types';
import React from 'react';

import OmadaLogo from 'components/shared/omada_logo';

function HeaderLogo({ logo, link_to_corporate_site, kairos_base_url }) {
  const logoToRender = logo ? (
    <img src={logo} className='site-header__logo qa-custom-logo' alt='logo' />
  ) : (
    <OmadaLogo classNames='landing-page--mobile-logo' />
  );

  if (link_to_corporate_site) {
    return (
      <a href={kairos_base_url} aria-label='Omada Homepage'>
        {logoToRender}
      </a>
    );
  }
  return logoToRender;
}

const { bool, string } = PropTypes;

HeaderLogo.propTypes = {
  kairos_base_url: string,
  link_to_corporate_site: bool,
  logo: string,
};

export default HeaderLogo;

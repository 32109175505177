// Polyfills:
import 'core-js/fn/array/from';
import 'core-js/fn/array/includes';
import 'core-js/fn/object/assign';
import 'core-js/fn/string/includes';
import 'core-js/fn/symbol/iterator';
import 'core-js/es6/symbol';

import React from 'react';
import { render } from 'react-dom';
import ReactModal from 'react-modal';

import ChoiceModal from '../components/landing_page/choice_modal';
import ClosedDeploymentModal from '../components/landing_page/closed_deployment_modal';
import GenericCoverageCheckModal from '../components/landing_page/generic_coverage_check/generic_coverage_check_modal';
import LandingPageContainer from '../components/landing_page/landing_page_container';

ReactModal.setAppElement('.js-app-element');

$.ajaxSetup({
  headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
});

// To keep things simple, we decided to just pass-through the snake-cased prop names from Ruby, but they don't match the
// camel-cased names in JavaScript. Maybe we want automatic camel-casing or explicit mapping of prop names?
function selectPropsFromRuby(keys) {
  const selectedProps = {};
  const dataAvailable = {
    ...window.dataFromRuby,
    mixpanel: window.mixpanel,
  };
  keys.forEach((key) => {
    selectedProps[key] = dataAvailable[key];
  });
  return selectedProps;
}

const componentsMap = {
  '.js-closed-deployment-modal': {
    component: ClosedDeploymentModal,
  },
  '.js-choice-modal': {
    component: ChoiceModal,
    propsFromRuby: [
      'choice_modal_bottom_is_type2',
      'choice_modal_bottom_opportunity',
      'choice_modal_bottom_text',
      'choice_modal_bottom_url',
      'choice_modal_explanation',
      'choice_modal_heading',
      'choice_modal_submit_button_text',
      'choice_modal_top_is_type2',
      'choice_modal_top_opportunity',
      'choice_modal_top_text',
      'choice_modal_top_url',
      'choice_modal_middle_is_type2',
      'choice_modal_middle_opportunity',
      'choice_modal_middle_text',
      'choice_modal_middle_url',
      'mixpanel',
    ],
  },
  '.js-coverage-check': {
    component: GenericCoverageCheckModal,
  },
  '.js-landing-page-container': {
    component: LandingPageContainer,
  },
};

Object.keys(componentsMap).forEach((selector) => {
  const possibleNode = document.querySelector(selector);
  if (possibleNode) {
    const componentInfo = componentsMap[selector];
    const Component = componentInfo.component;
    const propKeys = componentInfo.propsFromRuby || [];
    const props = selectPropsFromRuby(propKeys);
    render(<Component {...props} />, possibleNode);
  }
});

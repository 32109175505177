import React from 'react';

function FacebookIcon() {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#facebook-icon-clip-path)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M24.2895 12C25.2336 12 26 12.7664 26 13.7105V24.2895C26 25.2336 25.2336 26 24.2895 26H21.3783V20.725H23.1994L23.5459 18.4658H21.3783V16.9997C21.3783 16.3817 21.6811 15.7792 22.652 15.7792H23.6375V13.8558C23.6375 13.8558 22.7431 13.7032 21.888 13.7032C20.1028 13.7032 18.936 14.7852 18.936 16.7439V18.4658H16.9515V20.725H18.936V26H13.7105C12.7664 26 12 25.2336 12 24.2895V13.7105C12 12.7664 12.7664 12 13.7105 12H24.2895Z'
          fill='#282E33'
        />
      </g>
      <defs>
        <clipPath id='facebook-icon-clip-path'>
          <rect width='14' height='14' fill='white' transform='translate(12 12)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookIcon;

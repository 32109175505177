import React from 'react';

const RightArrow = () => {
  return (
    <svg
      width='16'
      height='16'
      style={{ top: '3px', position: 'relative' }}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0585 7.33268L7.5299 3.80409C7.26955 3.54374 7.26955 3.12163 7.5299 2.86128C7.79025 2.60093 8.21236 2.60093 8.47271 2.86128L13.1394 7.52794C13.3997 7.78829 13.3997 8.2104 13.1394 8.47075L8.47271 13.1374C8.21236 13.3978 7.79025 13.3978 7.5299 13.1374C7.26955 12.8771 7.26955 12.455 7.5299 12.1946L11.0585 8.66602H3.33464C2.96645 8.66602 2.66797 8.36754 2.66797 7.99935C2.66797 7.63116 2.96645 7.33268 3.33464 7.33268H11.0585Z'
        fill='#F3724A'
      />
    </svg>
  );
};

export default RightArrow;

import React from 'react';

import ModalContainer from '../components/modal_container';
import CoveredPrograms from '../components/shared/covered_programs';
import Devices from '../components/shared/devices';
import EligibilityBanner2021 from '../components/shared/eligibility_banner_2021';
import FAQ from '../components/shared/faq';
import HeroSection from '../components/shared/hero_section';
import LandingPageFooter from '../components/shared/landing_page_footer';
import LandingPageHeader from '../components/shared/landing_page_header';
import ProductFeatures from '../components/shared/product_features';
import Testimonials from '../components/shared/testimonials';
import IMAGE_NAME_TO_BACKGROUND_CLASSES from '../utils/image_name_to_bg_classes';
import { combineDiabetesConditions, getConditionsWithDevices, hasNonfreeProgram } from '../utils/possible_conditions';

function LandingPageDesignWithTestimonials2023() {
  const { hero_image_url: imageUrl, show_physician_care_content } = window.dataFromRuby;
  let { possible_conditions_at_entry } = window.dataFromRuby;
  const forceConfig = !possible_conditions_at_entry;
  possible_conditions_at_entry = possible_conditions_at_entry || [];
  const multipleCoveredConditions =
    possible_conditions_at_entry && combineDiabetesConditions(possible_conditions_at_entry).length > 1;

  const determineBackgroundClasses = () => {
    const matchingImageName = Object.keys(IMAGE_NAME_TO_BACKGROUND_CLASSES).find((imageName) => {
      return imageUrl && imageUrl.includes(imageName);
    });

    return matchingImageName
      ? IMAGE_NAME_TO_BACKGROUND_CLASSES[matchingImageName]
      : { bgClass: null, bgStaticClass: null };
  };

  const { bgClass, bgStaticClass } = determineBackgroundClasses();
  const hasNonFreeProgramInConditions = hasNonfreeProgram(possible_conditions_at_entry);

  const headerConfig = {
    ctaText: 'Join now',
  };
  const heroSectionConfig = {
    headerText: 'Real support for better health',
    bodyText:
      'Lose weight and improve your overall health with the help of a personal health coach, smart scale, and more. <strong>All at no cost to you.</strong>',
    videoText: 'Play the video',
    imageUrl,
    bgClass,
    bgStaticClass,
  };
  const hasDevicesToPresent = getConditionsWithDevices(possible_conditions_at_entry).length > 0;

  return (
    <main className={bgClass}>
      <LandingPageHeader config={headerConfig} />
      <HeroSection config={heroSectionConfig} forceConfig={forceConfig} />
      {multipleCoveredConditions && <CoveredPrograms />}
      <ProductFeatures
        possible_conditions_at_entry={possible_conditions_at_entry}
        show_physician_care_content={show_physician_care_content}
      />
      <EligibilityBanner2021 has_nonfree_program={hasNonFreeProgramInConditions} />
      <Testimonials />
      <EligibilityBanner2021 has_nonfree_program={hasNonFreeProgramInConditions} />
      {hasDevicesToPresent && <Devices />}
      <FAQ />
      {hasDevicesToPresent && <EligibilityBanner2021 has_nonfree_program={hasNonFreeProgramInConditions} />}
      <ModalContainer />
      <LandingPageFooter showCards={false} />
    </main>
  );
}

export default LandingPageDesignWithTestimonials2023;

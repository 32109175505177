import HealthInsurer from 'models/health_insurer';
import Employer from 'models/employer';

const typeaheadLoadOptionsHandler = (error, response, callback, valueKey = 'orange_database_id') => {
  if (response && response.ok) {
    const formatted = response.body.map((el) => {
      return { value: el[valueKey].toString(), label: el.name };
    });
    callback(formatted);
  } else {
    callback([]);
  }
};

export const loadEmployers = (inputValue, callback) => {
  Employer.search(inputValue, (err, response) => {
    typeaheadLoadOptionsHandler(err, response, callback);
  });
};

export const loadInsurers = (inputValue, callback, options = {}) => {
  const { params, valueKey } = options;
  const result = HealthInsurer.search(inputValue, (err, response) => {
    typeaheadLoadOptionsHandler(err, response, callback, valueKey);
  }, params);
};

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from '@omada/react-accessible-accordion';
import React, { useState } from 'react';

import ArrowIcon from '../../icons/arrow-icon';
import IntroModal from '../../intro_modal';

function questions(modalCallback) {
  const faq = window.dataFromRuby.faq;

  const what_is_omada_answer = (
    <>
      {faq.what_is_omada.answer}
      &nbsp;
      <button className='ev-video-button' type='button' onClick={() => modalCallback(true)}>
        {faq.what_is_omada.answer_link_text}
      </button>
    </>
  );

  const how_to_get_started_answer = (
    <ul className='ev-answer-list'>
      <li>
        <a
          href={window.dataFromRuby?.cta_link_href}
          className={`${window.dataFromRuby.cta_link_trigger_class} cta-trigger`}
        >
          {faq.how_to_get_started.answer.bullet_point_1.link}
        </a>
        : {faq.how_to_get_started.answer.bullet_point_1.text}
      </li>
      <li>{faq.how_to_get_started.answer.bullet_point_2}</li>
      <li>{faq.how_to_get_started.answer.bullet_point_3}</li>
    </ul>
  );

  const privacy_answer = (
    <>
      {faq.privacy.answer.text}
      &nbsp;
      <a href='https://www.omadahealth.com/privacy-policy' target='_blank' rel='noopener noreferrer'>
        {faq.privacy.answer.link_1_text}
      </a>{' '}
      and{' '}
      <a href='https://www.omadahealth.com/terms-of-use' target='_blank' rel='noopener noreferrer'>
        {faq.privacy.answer.link_2_text}
      </a>
      .
    </>
  );

  return [
    [faq.what_is_omada.question, what_is_omada_answer],
    [faq.family_eligible.question, faq.family_eligible.answer],
    [faq.how_to_get_started.question, how_to_get_started_answer],
    [faq.keep_the_tools.question, faq.keep_the_tools.answer],
    [faq.time_commitment.question, faq.time_commitment.answer],
    [faq.privacy.question, privacy_answer],
  ];
}

function FAQ() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className='t-bg--white ev-subsection-container__desktop u-pos--r'>
      <div className='ev-faq-container u-pos--r'>
        <div className='product-features__header u-m--a u-text--c u-4mb--m'>
          <h2 className='ev-header-base--sm u-4mb--l qa-title'>{window.dataFromRuby.faq.header}</h2>
        </div>

        <div className='ev-body-base ev-faq-subheader qa-subheader'>{window.dataFromRuby.faq.subheader}</div>

        <Accordion className='ev-questions-list' allowMultipleExpanded allowZeroExpanded>
          {questions(setIsModalOpen).map(([question, answer], index) => (
            <AccordionItem key={question} className='ev-single-question-and-answer'>
              <AccordionItemHeading>
                <AccordionItemButton className='ev-question-text'>
                  <span>
                    <span aria-hidden>+ </span>
                    {question}
                  </span>
                  <AccordionItemState>
                    {(state) => {
                      return (
                        <span id={`qa-faq-arrow-${index}`} className={state.expanded ? 'arrow-up' : ''} aria-hidden>
                          <ArrowIcon color='#000' />
                        </span>
                      );
                    }}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className='ev-answer-text'>{answer}</AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <IntroModal handleCloseModal={() => setIsModalOpen(false)} isOpen={isModalOpen} />
    </div>
  );
}

export default FAQ;

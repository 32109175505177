import React from 'react';

function ServerError() {
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;
  return (
    <div className='gcc__results-container gcc__results-container--horizontal'>
      <div className='left'>
        <p className='gcc__results-heading'>Sorry! Something went wrong with your request.</p>
        <p className='gcc__results-content'>Please refresh the page and try again.</p>
        <p className='gcc__results-content'>
          If this problem persists, please contact Omada support at{' '}
          <a href='mailto:support@omadahealth.com' className='t-c--main gcc__results-strong-content'>
            support@omadahealth.com
          </a>
          .
        </p>
      </div>
      <div className='right'>
        <img
          role='presentation'
          alt='covered'
          src={`${staticAssetsUrlBase}/generic_coverage_check/maybe-covered.png`}
        />
      </div>
    </div>
  );
}

export default ServerError;

import React from 'react';

function TwitterIcon() {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.4027 24.6882C21.6858 24.6882 24.5754 20.3113 24.5754 16.5156C24.5754 16.3913 24.5754 16.2675 24.567 16.1443C25.1291 15.7377 25.6144 15.2342 26 14.6575C25.4758 14.8898 24.9196 15.0421 24.3502 15.1094C24.9497 14.7506 25.3987 14.1857 25.613 13.5207C25.0492 13.8552 24.4323 14.0911 23.7891 14.2179C23.246 13.6404 22.4875 13.3125 21.6948 13.3125C20.1175 13.3125 18.8197 14.6103 18.8197 16.1875C18.8197 16.4063 18.8447 16.6245 18.8942 16.8376C16.5858 16.7219 14.4322 15.63 12.9744 13.8365C12.2163 15.1416 12.6086 16.8328 13.8637 17.6708C13.4066 17.6573 12.9594 17.534 12.56 17.3113V17.3477C12.5604 18.7098 13.5293 19.8937 14.8644 20.1634C14.4416 20.2787 13.9978 20.2956 13.5675 20.2127C13.9428 21.3799 15.0252 22.1844 16.251 22.2074C15.2341 23.0066 13.9771 23.4407 12.6838 23.4394C12.4552 23.439 12.2269 23.4251 12 23.398C13.3134 24.2408 14.8421 24.688 16.4027 24.686'
        fill='#282E33'
      />
    </svg>
  );
}

export default TwitterIcon;

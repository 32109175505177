const sliderSetters = (sliderOverrides = {}) => {
  return {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    dotsClass: 'what-is-omada__custom-dot slick-dots',
    arrows: false,
    centerMode: true,
    focusOnSelect: false,
    infinite: false,
    centerPadding: '32px',
    ...sliderOverrides,
  };
};

export default sliderSetters;

import React from 'react';

function MediumCompanyIcon() {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M26 12H12V26H26V12Z' fill='#282E33' />
      <path
        d='M15.3418 16.6824C15.3535 16.5669 15.3095 16.4528 15.2233 16.3751L14.3451 15.3172V15.1592H17.0718L19.1794 19.7814L21.0323 15.1592H23.6317V15.3172L22.8809 16.0371C22.8161 16.0864 22.784 16.1675 22.7974 16.2478V21.5372C22.784 21.6175 22.8161 21.6986 22.8809 21.7479L23.6141 22.4678V22.6259H19.9258V22.4678L20.6854 21.7303C20.7601 21.6558 20.7601 21.6338 20.7601 21.5197V17.2443L18.6481 22.6082H18.3627L15.9038 17.2443V20.8393C15.8834 20.9904 15.9336 21.1426 16.0399 21.2519L17.0279 22.4502V22.6082H14.2266V22.4502L15.2145 21.2519C15.3201 21.1424 15.3674 20.9892 15.3418 20.8393V16.6824Z'
        fill='white'
      />
    </svg>
  );
}

export default MediumCompanyIcon;

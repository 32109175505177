import PropTypes from 'prop-types';
import React from 'react';

const NavbarCircle = ({ active }) => {
  return (
    <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.25 7A6.25 6.25 0 1 1 .75 7a6.25 6.25 0 0 1 12.5 0z'
        stroke={active ? '#fff' : '#B2B2B2'}
        strokeWidth='1.5'
      />
    </svg>
  );
};

const { bool } = PropTypes;
NavbarCircle.propTypes = {
  active: bool,
};

NavbarCircle.defaultProps = {
  active: false,
};

export default NavbarCircle;

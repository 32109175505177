import React from 'react';
import ReactModal from 'react-modal';
import { parseQueryParams } from 'utils/parse_query_params';

import GenericCoverageCheckContainer from './generic_coverage_check_container';

class GenericCoverageCheckModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: this.openModalOnLoad() };
  }

  componentDidMount() {
    const triggers = document.querySelectorAll('.js-coverage-check-trigger');
    Array.from(triggers).forEach((trigger) => trigger.addEventListener('click', this.handleTriggerClick));
  }

  openModalOnLoad = () => {
    const queryParams = parseQueryParams(window.location.search);
    return 'check-coverage' in queryParams;
  };

  handleTriggerClick = (event) => {
    event.preventDefault();
    this.setState({
      isOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <ReactModal
        isOpen={this.state.isOpen}
        onClose={this.closeModal}
        contentLabel='Coverage check modal'
        style={{
          content: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            border: 'none !important',
            backgroundColor: 'transparent !important',
          },
        }}
        overlayClassName={{
          base: 'full-screen-modal-overlay',
          afterOpen: 'full-screen-modal-overlay--visible',
          beforeClose: 'full-screen-modal-overlay--hidden',
        }}
        shouldCloseOnOverlayClick={false}
      >
        <GenericCoverageCheckContainer closeModal={this.closeModal} />
      </ReactModal>
    );
  }
}

export default GenericCoverageCheckModal;

import React from 'react';

const ProviderIcon = () => {
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.3535 15.3305H25.2715L24.953 16.052C24.7385 16.5395 24.628 17.0595 24.628 17.5925C24.628 18.119 24.7775 18.6325 25.0635 19.0745L25.59 19.9C25.915 20.407 26.396 20.8035 26.9615 21.018C27.54 21.239 28.1445 21.3495 28.762 21.3495H29.7435V30.5665H16.3535L16.412 21.993H9.78198C9.97048 20.2705 10.6075 18.86 11.6865 17.768C12.759 16.6825 14.319 15.87 16.3535 15.3305Z'
        fill='black'
      />
      <path
        d='M25.1676 8.5315C25.1676 11.593 22.6846 14.063 19.6231 14.063C16.5551 14.063 14.0656 11.5865 14.0656 8.5315C14.0656 7.225 14.5141 6.029 15.2616 5.0865C16.2951 3.819 17.8551 3 19.6231 3C22.6846 3 25.1676 5.4765 25.1676 8.5315Z'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M29.7499 21.356V30.586H16.2949'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.016 20.7385C10.029 20.68 10.0485 20.6475 10.081 20.589C11.3485 17.482 14.0395 15.3175 17.1725 15.3175H25.2'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.6705 22.0515H7V32.575H16.36V22.0515H14.709'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.78198 25.906H13.643'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.78198 27.3945H13.643'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.78198 28.9025H13.643'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.6311 20.81H8.79407V23.371H14.6311V20.81Z'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      {/* eslint-disable-next-line prettier/prettier */}
      <path
        d='M14.605 20.7905H8.74854V23.3645H14.605V20.7905Z'
        fill='black'
      />
      <path
        d='M14.2734 6.69849C16.0609 6.69849 16.0609 7.88799 17.8484 7.88799C19.6359 7.88799 19.6359 6.69849 21.4234 6.69849C23.2109 6.69849 23.2109 7.88799 24.9984 7.88799'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinejoin='round'
      />
      <path
        d='M25.044 7.8425C23.2565 7.8425 23.2565 6.6465 21.4755 6.6465C19.6945 6.6465 19.688 7.8425 17.907 7.8425C16.1195 7.8425 16.1195 6.6465 14.3385 6.6465L15.5085 4.6445L17.348 3.4615L18.856 3.0715L21.001 3.0975L22.7755 3.962L24.4655 5.6455L25.2325 7.4655L25.0635 7.953'
        fill='black'
      />
      <path
        d='M27.3711 17.5665L28.1836 18.3725L30.0491 16.5265'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.71 21.4665C30.959 21.4665 32.7855 19.64 32.7855 17.391C32.7855 15.142 30.959 13.3155 28.71 13.3155C26.4545 13.3155 24.6345 15.142 24.6345 17.391C24.6345 19.64 26.4545 21.4665 28.71 21.4665Z'
        stroke='black'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6719 19.2695C17.1399 19.2695 17.5234 18.8795 17.5234 18.405C17.5234 17.924 17.1399 17.5405 16.6719 17.5405C16.2039 17.5405 15.8204 17.9305 15.8204 18.405C15.8204 18.8795 16.2039 19.2695 16.6719 19.2695Z'
        stroke='white'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.6851 15.402V17.3975'
        stroke='white'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.7812 26.231H20.4617C20.4617 26.231 20.4617 24.6775 20.4617 23.3905C20.4617 22.1035 21.6512 20.8685 22.6652 20.8685'
        fill='black'
      />
      <path
        d='M21.7812 26.231H20.4617C20.4617 26.231 20.4617 24.6775 20.4617 23.3905C20.4617 22.1035 21.6512 20.8685 22.6652 20.8685'
        stroke='white'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.4904 26.231H24.8099C24.8099 26.231 24.8099 24.6775 24.8099 23.3905C24.8099 22.1035 23.6204 20.8685 22.6064 20.8685'
        fill='black'
      />
      <path
        d='M23.4904 26.231H24.8099C24.8099 26.231 24.8099 24.6775 24.8099 23.3905C24.8099 22.1035 23.6204 20.8685 22.6064 20.8685'
        stroke='white'
        strokeWidth='0.21203'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.665 20.693V15.571'
        stroke='white'
        strokeWidth='0.22256'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ProviderIcon;

import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';

function ArrowInCircle({ classNames, direction, onClick, size, filterId, ariaLabel }) {
  const SIZE_MAPPING = {
    large: 108,
    small: 66,
  };

  const sizeInPixels = SIZE_MAPPING[size];

  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox={`0 0 ${sizeInPixels} ${sizeInPixels}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={`arrow-icon ${classNames}`}
      aria-label={ariaLabel}
      role='button'
    >
      <g filter={`url(#${filterId})`}>
        <circle
          r={`${sizeInPixels / 2.7}`}
          transform={`matrix(-1 0 0 1 ${sizeInPixels / 2} ${sizeInPixels / 2})`}
          className='ev-arrow-icon-circle'
        />
        <path
          d={`M${sizeInPixels / 1.9} ${sizeInPixels / 2.5}L${sizeInPixels / 2.2} ${sizeInPixels / 2.1}L${
            sizeInPixels / 1.9
          } ${sizeInPixels / 1.75}`}
          stroke='#282E33'
          strokeWidth={sizeInPixels / 36}
        />
      </g>
      <defs>
        <filter
          id={filterId}
          x='0'
          y='0'
          width={sizeInPixels}
          height={sizeInPixels}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={sizeInPixels / 55} />
          <feGaussianBlur stdDeviation={sizeInPixels / 44} />
          <feColorMatrix type='matrix' values='0 0 0 0 0.121569 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.06 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={sizeInPixels / 55} />
          <feGaussianBlur stdDeviation={sizeInPixels / 15.5} />
          <feColorMatrix type='matrix' values='0 0 0 0 0.121569 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.04 0' />
          <feBlend mode='normal' in2='effect1_dropShadow' result='effect2_dropShadow' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
          <feOffset dy={sizeInPixels / 18} />
          <feGaussianBlur stdDeviation={sizeInPixels / 22} />
          <feColorMatrix type='matrix' values='0 0 0 0 0.121569 0 0 0 0 0.14902 0 0 0 0 0.2 0 0 0 0.1 0' />
          <feBlend mode='normal' in2='effect2_dropShadow' result='effect3_dropShadow' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
}

ArrowInCircle.defaultProps = {
  direction: 'left',
  size: 'large',
  filterId: `filter_${_uniqueId()}_ddd`,
};

const { func, string, oneOf } = PropTypes;

ArrowInCircle.propTypes = {
  direction: oneOf(['left', 'right']),
  size: oneOf(['large', 'small']),
  classNames: string,
  onClick: func,
  filterId: string,
  ariaLabel: string,
};

export default ArrowInCircle;

import React from 'react';

function CurrentlyClosed() {
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;
  return (
    <div className='gcc__results-container gcc__results-container--horizontal'>
      <div className='left'>
        <p className='gcc__results-heading'>Sorry! Applications are currently closed.</p>
        <p className='gcc__results-content'>
          Unfortunately, Omada is not currently accepting new applications through your sponsor at this time. We have
          either reached maximum enrollment for this phase or we have closed the program for now.
        </p>
        <p className='gcc__results-content'>
          Questions? Email <span className='gcc__results-strong-content'>support@omadahealth.com</span>
        </p>
      </div>
      <div className='right'>
        <img
          role='presentation'
          alt='covered'
          src={`${staticAssetsUrlBase}/generic_coverage_check/maybe-covered.png`}
        />
      </div>
    </div>
  );
}

export default CurrentlyClosed;

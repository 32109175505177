import PropTypes from 'prop-types';
import React from 'react';

function CtaButton({ classNames, ctaText, forceConfig, section }) {
  const { cta_link_text, cta_link_href, cta_link_trigger_class } = window.dataFromRuby;

  const label = forceConfig && ctaText ? ctaText : cta_link_text || ctaText;

  return (
    <a
      href={cta_link_href}
      section={section}
      event='cta'
      className={`${cta_link_trigger_class} ${classNames} cta-trigger`} // referenced by mixpanel.track_links in LandingPageContainer
      aria-label={label}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  );
}

const { bool, string } = PropTypes;

CtaButton.propTypes = {
  classNames: string,
  ctaText: string,
  forceConfig: bool,
  section: string.isRequired,
};

export default CtaButton;

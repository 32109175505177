import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import keydownEnterHandler from '../../utils/keydown_enter_handler';

function ProgramInfoTile2021({ tileData, tileName, activeTileName, onProgramInfoClick, isMobile }) {
  const tileContainerClasses = classnames({
    active: tileName === activeTileName,
    'product-features__desktop-tile': !isMobile,
    'product-features__mobile-tile': isMobile,
  });
  const Element = isMobile ? 'div' : 'li';

  return (
    <Element
      key={tileName}
      tabIndex='0'
      className={tileContainerClasses}
      onKeyDown={(e) => keydownEnterHandler(e, onProgramInfoClick, tileName)}
      onClick={() => onProgramInfoClick(tileName)}
      aria-current={tileName === activeTileName}
    >
      <div role='button' className='u-flex product-features__tile-text-container ev-body-base'>
        <div className='u-4mr--xs'>{tileData.icon()}</div>
        <div>
          <div className='u-text--b product-features__info-title qa-info-title' role='heading' aria-level='3'>
            {tileData.title}
          </div>
          <div className='product-features__subtext' dangerouslySetInnerHTML={{ __html: tileData.subtext }} />
        </div>
      </div>
    </Element>
  );
}

const { string, shape, bool, func } = PropTypes;
ProgramInfoTile2021.propTypes = {
  tileData: shape({
    title: string.isRequired,
    subtext: string.isRequired,
    icon: func.isRequired,
  }),
  onProgramInfoClick: func,
  activeTileName: string,
  tileName: string.isRequired,
  isMobile: bool,
};

ProgramInfoTile2021.defaultProps = {
  isMobile: false,
  onProgramInfoClick: () => {},
};

export default ProgramInfoTile2021;

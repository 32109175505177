import Modal from '@omada/react-therapy/src/modals/Modal';
import ModalContent from '@omada/react-therapy/src/modals/ModalContent';
import PropTypes from 'prop-types';
import React from 'react';

import ClosedDeploymentModalContent from './closed_deployment_modal_content';

import { parseQueryParams } from 'utils/parse_query_params';

class ClosedDeploymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.configuration = window.dataFromRuby;

    this.state = { isOpen: this.startingState() };
  }

  componentDidMount() {
    const triggers = document.querySelectorAll('.js-closed-deployment-modal-trigger');
    Array.from(triggers).forEach((trigger) => trigger.addEventListener('click', this.handleTriggerClick));
  }

  startingState = () => {
    const queryParams = parseQueryParams(window.location.search);
    return 'join-the-waitlist' in queryParams;
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleTriggerClick = (event) => {
    event.preventDefault();
    this.setState({
      isOpen: true,
    });
  };

  render() {
    const { configuration } = this;
    return (
      <Modal isOpen={this.state.isOpen} onClose={this.closeModal} a11yLabel='Message dialog'>
        <ModalContent>
          <ClosedDeploymentModalContent
            title={configuration.deployment_closed_heading}
            companyName={configuration.company_name}
            content={configuration.deployment_closed_explanation}
            deploymentCode={configuration.deployment_code}
            kairosBaseUrl={configuration.kairos_base_url}
            shouldRenderForm={configuration.show_closed_deployment_form}
          />
          <p className='u-4mb--xxl u-4mt--xxl'>
            Questions? Email{' '}
            <a href='mailto:support@omadahealth.com' className='t-c--main-5'>
              support@omadahealth.com
            </a>
          </p>
        </ModalContent>
      </Modal>
    );
  }
}

export default ClosedDeploymentModal;

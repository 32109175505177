function setURLParam(paramName, paramVal) {
  const url = new URL(window.location);
  url.searchParams.set(paramName, paramVal);
  window.history.pushState(null, '', url);
}

function removeURLParam(paramName) {
  const url = new URL(window.location);
  url.searchParams.delete(paramName);
  window.history.pushState(null, '', url);
}

function getURLParam(paramName) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(paramName);
}

export { setURLParam, removeURLParam, getURLParam };

import _uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import ArrowInCircle from '../../icons/arrow_in_circle';
import Checkmark from '../../icons/checkmark';
import sliderSetters from '../../utils/slider_settings';

function DevicesDesktopSlider({ programInfoToPresent }) {
  const staticAssetsUrlBase = window.dataFromRuby.lando_static_assets_cloudfront_url_base;
  const sliderSettings = sliderSetters({
    arrows: true,
    autoplay: false,
    dots: false,
    centerPadding: '0px',
    infinite: true,
    nextArrow: (
      <ArrowInCircle
        classNames='ev-devices-desktop__arrow-icon right'
        direction='right'
        filterId={`filter_${_uniqueId()}_ddd`}
        ariaLabel='Next Slide'
      />
    ),
    prevArrow: (
      <ArrowInCircle
        classNames='ev-devices-desktop__arrow-icon left'
        direction='left'
        filterId={`filter_${_uniqueId()}_ddd`}
        ariaLabel='Previous Slide'
      />
    ),
  });

  const renderDeviceInfoTile = (programInfo) => {
    const { bodyText, bulletTextList, condition, title, imageAlt, img } = programInfo;
    return (
      <div className={`device-at-no-cost-widget__content u-flex qa-device-condition-${condition}`} key={title}>
        <div className='ev-device-at-no-cost-widget__left-side'>
          <h1 className='header'>{title}</h1>
          <p className='body'>{bodyText}</p>
          <ul className='list'>
            {bulletTextList.map((text) => (
              <li key={text} className='bullet'>
                <Checkmark />
                {text}
              </li>
            ))}
          </ul>
        </div>
        <div className='ev-device-at-no-cost-widget__right-side'>
          <img alt={imageAlt} src={`${staticAssetsUrlBase}/landing_page/${img}`} role='presentation' />
        </div>
      </div>
    );
  };

  return (
    <div className='ev-device-at-no-cost-widget'>
      <Slider
        arrows={sliderSettings.arrows}
        autoplay={sliderSettings.autoplay}
        autoplaySpeed={sliderSettings.autoplaySpeed}
        centerMode={sliderSettings.centerMode}
        centerPadding={sliderSettings.centerPadding}
        dots={sliderSettings.dots}
        dotsClass={sliderSettings.dotsClass}
        focusOnSelect={sliderSettings.focusOnSelect}
        infinite={sliderSettings.infinite}
        nextArrow={sliderSettings.nextArrow}
        prevArrow={sliderSettings.prevArrow}
        slidesToScroll={sliderSettings.slidesToScroll}
        slidesToShow={sliderSettings.slidesToShow}
      >
        {programInfoToPresent.map((programInfo) => renderDeviceInfoTile(programInfo))}
      </Slider>
    </div>
  );
}

const { arrayOf, shape, string } = PropTypes;

DevicesDesktopSlider.propTypes = {
  programInfoToPresent: arrayOf(
    shape({
      title: string.isRequired,
      className: string,
      img: string.isRequired,
      imageAlt: string.isRequired,
      bodyText: string.isRequired,
      bulletTextList: arrayOf(string).isRequired,
    }),
  ).isRequired,
};

export default DevicesDesktopSlider;

import React from 'react';

const Info = () => {
  return (
    <svg alt='info-icon' width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.0013 0.666992C3.94978 0.666992 0.667969 3.9488 0.667969 8.00033C0.667969 12.0518 3.94978 15.3337 8.0013 15.3337C12.0528 15.3337 15.3346 12.0518 15.3346 8.00033C15.3346 3.9488 12.0528 0.666992 8.0013 0.666992ZM8.0013 2.00033C11.3164 2.00033 14.0013 4.68518 14.0013 8.00033C14.0013 11.3155 11.3164 14.0003 8.0013 14.0003C4.68616 14.0003 2.0013 11.3155 2.0013 8.00033C2.0013 4.68518 4.68616 2.00033 8.0013 2.00033ZM8.83464 4.16329C8.83464 4.62996 8.46426 5.00033 8.00501 5.00033C7.53834 5.00033 7.16797 4.62996 7.16797 4.16329C7.16797 3.70403 7.53834 3.33366 8.00501 3.33366C8.46426 3.33366 8.83464 3.70403 8.83464 4.16329ZM9.33464 12.667C9.70283 12.667 10.0013 12.3685 10.0013 12.0003C10.0013 11.6321 9.70283 11.3337 9.33464 11.3337H8.66797V6.33366C8.66797 5.96547 8.36949 5.66699 8.0013 5.66699H6.66797C6.29978 5.66699 6.0013 5.96547 6.0013 6.33366C6.0013 6.70185 6.29978 7.00033 6.66797 7.00033H7.33464V11.3337H6.66797C6.29978 11.3337 6.0013 11.6321 6.0013 12.0003C6.0013 12.3685 6.29978 12.667 6.66797 12.667H8.0013H9.33464Z'
        fill='#868E96'
      />
    </svg>
  );
};

export default Info;

import classnames from 'classnames';
import PropTypes, { element } from 'prop-types';
import React from 'react';

import keydownEnterHandler from '../utils/keydown_enter_handler';

function ProgramInfoTile({ tileData, tileName, activeTileName, onProgramInfoClick, isMobile, isPresentation }) {
  const tileContainerClasses = classnames('what-is-omada__desktop-tile u-4pl--xxl u-4pr--s u-4mb--s', {
    active: tileName === activeTileName,
  });

  const layoutClasses = classnames('u-flex u-4pb--s u-4pt--xl u-4pb--m', {
    'u-flex-column': isMobile,
  });

  if (isMobile) {
    // In some places, this component's image needs to be hidden from screenreaders with 'role=presentation'
    // As the image is passed here as a React element, the only way to add the role is by cloning the element with the added property.
    const tileReactElement = tileData.icon();
    const imgToRender = isPresentation
      ? React.cloneElement(tileReactElement, { role: 'presentation' })
      : tileReactElement;
    return (
      <div key={tileName} className='what-is-omada__mobile-tile'>
        <div>{imgToRender}</div>
        <div className='u-flex-center--v u-justify-content-start tile-text'>
          <div role='heading' aria-level='3' className='u-text--b u-h5 u-4mt--m u-text--c qa-info-title'>
            {tileData.title}
          </div>
          <div className='t-c--grey-5 u-4mt--s u-text--c what-is-omada__info-subtext'>{tileData.subtext}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      key={tileName}
      className={tileContainerClasses}
      onKeyDown={(e) => {
        keydownEnterHandler(e, onProgramInfoClick, tileName);
      }}
      onClick={() => onProgramInfoClick(tileName)}
      role='button'
      tabIndex='0'
    >
      <div className={layoutClasses}>
        <div className='u-4mr--l'>{tileData.icon()}</div>
        <div>
          <div role='heading' aria-level='3' className='u-text--b u-h5 u-4mt--xxs qa-info-title'>
            {tileData.title}
          </div>
          <div className='t-c--grey-5 u-4mt--xxs what-is-omada__info-subtext'>{tileData.subtext}</div>
        </div>
      </div>
    </div>
  );
}

const { string, shape, bool, func } = PropTypes;
ProgramInfoTile.propTypes = {
  tileData: shape({
    title: string.isRequired,
    subtext: string.isRequired,
    icon: func.isRequired,
  }),
  onProgramInfoClick: func,
  activeTileName: string,
  tileName: string.isRequired,
  isMobile: bool,
  isPresentation: bool,
};

ProgramInfoTile.defaultProps = {
  isMobile: false,
  isPresentation: false,
};

export default ProgramInfoTile;

import PropTypes from 'prop-types';
import React from 'react';

class ClosedDeploymentModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emailAddressValue: '', consentValue: false, displayState: 'form' };
  }

  isSubmitEnabled = () => {
    return this.state.emailAddressValue && this.state.consentValue;
  };

  handleEmailChange = (event) => {
    this.setState({ emailAddressValue: event.target.value });
  };

  handleConsentChange = (event) => {
    this.setState({ consentValue: event.target.checked });
  };

  handleSubmit = (event) => {
    const { emailAddressValue, consentValue } = this.state;
    const { companyName, deploymentCode, kairosBaseUrl } = this.props;
    event.preventDefault();

    jQuery.support.cors = true;
    return $.ajax({
      url: `${kairosBaseUrl}/api/v1/lead_generations/`,
      dataType: 'jsonp',
      timeout: 5000,
      type: 'GET',
      xhrFields: {
        withCredentials: true,
      },
      cache: false,
      data: {
        risk_screener_lead: {
          email: emailAddressValue,
          contact_consent_required: true,
          contact_consent: consentValue,
          company_name: companyName,
          deployment_code: deploymentCode,
          lead_source: 'Closed Deployment',
        },
      },
      success: () => {
        // mixpanel.track('Lead Submitted', {
        //   lead_type: 'closed_deployment'
        // });
        this.setState({ displayState: 'success' });
      },
      error: () => {
        this.setState({ displayState: 'error' });
      },
      complete: () => {},
    });
  };

  renderContent() {
    return (
      <div className='closed-deployment-modal__content' dangerouslySetInnerHTML={{ __html: this.props.content }} />
    );
  }

  renderErrorMessage() {
    return (
      <p>
        We weren't able to complete your email submission. Please email{' '}
        <a href='mailto:support@omadahealth.com'>support@omadahealth.com</a>.
      </p>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit} className='u-4mt--xs qa-closed-deployment-modal__form'>
        <label htmlFor='email_input' className='c-form__label'>
          Email address
        </label>
        <input
          type='email'
          className='c-form__text-input'
          onChange={this.handleEmailChange}
          value={this.state.emailAddressValue}
          placeholder='Enter email address'
          id='email_input'
          maxLength='100'
        />

        <div className='u-4mt--s'>
          <input
            type='checkbox'
            onChange={this.handleConsentChange}
            id='consent_to_contact'
            checked={this.state.consentValue}
          />
          <label htmlFor='consent_to_contact' className='u-4pl--xs'>
            I consent to being contacted
          </label>
        </div>
        <p className='u-4mt--s u-4mb--m t-c--dark-3 closed-deployment-modal__checkbox-consent'>
          By submitting my information, I authorize Omada Health, Inc. (“Omada”) to use my information for purposes of
          administering my potential enrollment in the Omada program. I understand that Omada may also retain my
          information and may contact me if an opportunity to enroll in the Omada program becomes available. I agree to
          receive email and/or other outreach from Omada through the contact information which I have provided regarding
          opportunities for which I may be eligible (eligibility is determined by (1) coverage and/or payment for the
          program and (2) pre-determined risk factors).
        </p>

        <div className='u-text--c'>
          <button type='submit' className='c-btn--primary' disabled={!this.isSubmitEnabled()}>
            Submit
          </button>
        </div>
      </form>
    );
  }

  renderSuccessMessage() {
    return <p>Your information has been submitted. Thanks for your interest in Omada!</p>;
  }

  render() {
    const { displayState } = this.state;
    switch (displayState) {
      case 'success':
        return this.renderSuccessMessage();
      case 'error':
        return this.renderErrorMessage();
      default:
        return (
          <div>
            <h2
              className='u-text--c u-4mb--l u-4pl--xxxl u-4pr--xxxl qa-closed-deployment-modal-title'
              dangerouslySetInnerHTML={{ __html: this.props.title }}
            />
            {this.renderContent()}
            {this.props.shouldRenderForm && this.renderForm()}
          </div>
        );
    }
  }
}

ClosedDeploymentModalContent.propTypes = {
  content: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  deploymentCode: PropTypes.string,
  kairosBaseUrl: PropTypes.string.isRequired,
  shouldRenderForm: PropTypes.bool.isRequired,
};

export default ClosedDeploymentModalContent;

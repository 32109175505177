import React from 'react';

import ChoiceModal from '../choice_modal';
import ClosedDeploymentModal from '../closed_deployment_modal';
import GenericCoverageCheckModal from '../generic_coverage_check/generic_coverage_check_modal';

const ModalContainer = () => {
  const choiceModalProps = {
    uses_choice_modal: window.dataFromRuby.uses_choice_modal,
    choice_modal_heading: window.dataFromRuby.choice_modal_heading,
    choice_modal_explanation: window.dataFromRuby.choice_modal_explanation,
    choice_modal_top_text: window.dataFromRuby.choice_modal_top_text,
    choice_modal_bottom_text: window.dataFromRuby.choice_modal_bottom_text,
    choice_modal_submit_button_text: window.dataFromRuby.choice_modal_submit_button_text,
    choice_modal_middle_text: window.dataFromRuby.choice_modal_middle_text,
    choice_modal_bottom_url: window.dataFromRuby.choice_modal_bottom_url,
    choice_modal_bottom_opportunity: window.dataFromRuby.choice_modal_bottom_opportunity,
    choice_modal_bottom_is_type2: window.dataFromRuby.choice_modal_bottom_is_type2,
    choice_modal_top_url: window.dataFromRuby.choice_modal_top_url,
    choice_modal_top_opportunity: window.dataFromRuby.choice_modal_top_opportunity,
    choice_modal_top_is_type2: window.dataFromRuby.choice_modal_top_is_type2,
    choice_modal_middle_url: window.dataFromRuby.choice_modal_middle_url,
    choice_modal_middle_opportunity: window.dataFromRuby.choice_modal_middle_opportunity,
    choice_modal_middle_is_type2: window.dataFromRuby.choice_modal_middle_is_type2,
    mixpanel: window.mixpanel,
  };
  return (
    <>
      <GenericCoverageCheckModal />
      <ChoiceModal {...choiceModalProps} />
      <ClosedDeploymentModal />
    </>
  );
};

export default ModalContainer;

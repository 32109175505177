import VisitorEvent from 'models/visitor_event';

const visitorEventCallback = (linkElement) => {
  const {
    attribution_session_id,
    cta_link_href,
    cta_link_text,
    cta_link_trigger_class,
    design_type,
    force_landing_page_design,
  } = window.dataFromRuby;
  const ctaSectionWhitelist = ['eligibility-banner', 'header', 'hero'];
  const sectionValue = linkElement.getAttribute('section');
  const section = ctaSectionWhitelist.includes(sectionValue) ? sectionValue : 'hero';

  const metadata = {
    action: cta_link_href,
    cta_class: cta_link_trigger_class,
    design_type: force_landing_page_design || design_type,
    label: cta_link_text, // TODO: override with ctaText if we're still using forceConfig, see CtaButton component
    section,
    type: 'button',
  };

  VisitorEvent.create(
    {
      attribution_session_id,
      visitor_event: { event: 'click_landing_page_cta', metadata },
    },
    (err, response) => {},
  );

  return metadata;
};

export default visitorEventCallback;

import PropTypes from 'prop-types';
import React from 'react';

function InputField(props) {
  const {
    fieldName,
    type,
    value,
    onChange,
    onBlur,
    label,
    placeholder,
    maxLength,
    errors,
    containerClasses,
    autoComplete,
  } = props;
  const inputId = `${fieldName}-input`;
  const hasErrors = !!errors[fieldName];

  return (
    <div className={containerClasses}>
      {label && (
        <label className='c-form__label form__label t-c--dark u-4mb--xs' htmlFor={inputId}>
          {label}
        </label>
      )}
      <input
        id={inputId}
        type={type || 'text'}
        className={`c-form__text-input gcc__form-input focus--teal u-4p--xs qa-${fieldName}`}
        name={fieldName}
        value={value}
        onBlur={() => {
          if (onBlur) onBlur();
        }}
        onChange={onChange}
        placeholder={placeholder}
        aria-invalid={hasErrors}
        maxLength={maxLength || '20'}
        autoComplete={autoComplete}
      />
      {hasErrors && (
        <div className={`c-form__field-error u-4mt--s qa-${fieldName}-error`}>{errors[fieldName].join(', ')}</div>
      )}
    </div>
  );
}

const { string, func, object } = PropTypes;

InputField.propTypes = {
  fieldName: string.isRequired,
  value: string.isRequired,
  onBlur: func,
  onChange: func.isRequired,
  errors: object.isRequired,
  label: string.isRequired,
  containerClasses: string,
  type: string,
  placeholder: string,
  maxLength: string,
  autoComplete: string,
};

InputField.defaultProps = {
  containerClasses: 'g-col u-4mb--l',
};

export default InputField;

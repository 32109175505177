import superagent from 'superagent';

const HealthInsurer  = {
  search: (contains, handler, params) => {
    superagent
      .post('/health_insurers/search')
      .send({ contains, ...params })
      .set('Accept', 'application/json')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .end(handler);
  }
}

export default HealthInsurer;

import React from 'react';

function LinkedinIcon() {
  return (
    <svg width='38' height='38' viewBox='0 0 38 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26 13.75C26 12.7841 25.2159 12 24.25 12H13.75C12.7841 12 12 12.7841 12 13.75V24.25C12 25.2159 12.7841 26 13.75 26H24.25C25.2159 26 26 25.2159 26 24.25V13.75Z'
        fill='#282E33'
      />
      <path d='M16.0149 16.8857H13.6699V23.9394H16.0149V16.8857Z' fill='white' />
      <path
        d='M14.8578 13.4844C14.0555 13.4844 13.5312 14.0118 13.5312 14.7032C13.5312 15.38 14.0395 15.922 14.8267 15.922H14.8419C15.6595 15.922 16.1685 15.38 16.1685 14.7032C16.1533 14.0118 15.6596 13.4844 14.8578 13.4844Z'
        fill='white'
      />
      <path
        d='M21.7699 16.7197C20.526 16.7197 19.9689 17.4038 19.6569 17.8843V16.8856H17.3125C17.3436 17.5472 17.3125 23.9392 17.3125 23.9392H19.6569V20C19.6569 19.7891 19.672 19.5783 19.7342 19.4276C19.9034 19.0065 20.2894 18.5704 20.9371 18.5704C21.7851 18.5704 22.1248 19.2174 22.1248 20.1652V23.9392H24.4693V19.8943C24.4693 17.7276 23.3125 16.7197 21.7699 16.7197Z'
        fill='white'
      />
    </svg>
  );
}

export default LinkedinIcon;

import React from 'react';

function Blob2() {
  return (
    <svg role='presentation' width='229' height='399' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M153.579 386.95c-177.384 0-165.786-361.137-85.174-373.748C149.018.592 276.63 60.222 330.962 209.777c54.553 150.158 0 177.173-177.383 177.173z'
        stroke='#F5F4F2'
        strokeWidth='23'
      />
    </svg>
  );
}

export default Blob2;

import PropTypes from 'prop-types';
import React from 'react';

function ArrowIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.57 10.6' width='18' height='10'>
      <path
        d='M18,1.4,19.63,3l-9.28,9L1.06,3,2.68,1.4l7.67,7.46Z'
        transform='translate(-1.06 -1.4)'
        fill={props.color}
      />
    </svg>
  );
}

ArrowIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default ArrowIcon;

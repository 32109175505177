/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

function SpanWithTooltip({ id, children, tooltipContent, placement, show, hide }) {
  const spanStyle = {
    color: '#ff6f31',
    cursor: 'pointer',
  };

  const [visible, setVisible] = useState(false);
  const target = useRef(null);

  const setTooltipVisibility = (shouldBeVisible) => {
    const delay = shouldBeVisible ? show || 300 : hide || 150;
    setTimeout(() => {
      setVisible(shouldBeVisible);
    }, delay);
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Escape') {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  return (
    <div
      tabIndex='0'
      aria-label={tooltipContent}
      role='tooltip'
      onMouseEnter={() => setTooltipVisibility(true)}
      onMouseLeave={() => setTooltipVisibility(false)}
      onFocus={() => setTooltipVisibility(true)}
      onBlur={() => setTooltipVisibility(false)}
    >
      <div aria-hidden='true'>
        <span style={spanStyle} ref={target}>
          {children}
        </span>
        <Overlay target={target.current} show={visible} placement='right'>
          <Tooltip id={id} className='tooltip-hover-expand'>
            {tooltipContent}
          </Tooltip>
        </Overlay>
      </div>
    </div>
  );
}

const { string, any } = PropTypes;

SpanWithTooltip.propTypes = {
  id: string.isRequired,
  children: any.isRequired,
  tooltipContent: any.isRequired,
  placement: string,
};

export default SpanWithTooltip;

import React from 'react';

function Checkmark() {
  return (
    <svg width='28' height='24' viewBox='0 0 28 24' fill='none' xmlns='http://www.w3.org/2000/svg' role='presentation'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 12.4687C0.577079 11.3146 1.73124 9.87186 3.75101 10.4489C5.48225 11.026 6.6364 12.7572 7.50202 15.0656C14.427 7.275 20.1977 1.79276 27.1227 0.0615234C27.9883 0.0615234 28.2768 0.0615234 27.6998 0.638602C20.1977 5.83231 12.9843 13.6229 7.21348 23.7217C6.92494 24.0103 6.6364 24.0103 6.34786 23.7217C5.19371 20.8363 4.32809 17.951 2.88539 15.0656C2.30831 13.6229 1.4427 12.4687 0 12.4687Z'
        fill='#282E33'
      />
    </svg>
  );
}

export default Checkmark;

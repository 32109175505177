import PropTypes from 'prop-types';
import React from 'react';

import OmadaLogo from '../../../shared/omada_logo';
import ClinicalIcon from '../../icons/clinical';
import { HeadWithBeakerBaseIcon } from '../../icons/head_with_beaker_icon';
import FacebookIcon from '../../icons/social_media/facebook-icon';
import InstagramIcon from '../../icons/social_media/instagram-icon';
import LinkedinIcon from '../../icons/social_media/linkedin-icon';
import MediumCompanyIcon from '../../icons/social_media/medium-company-icon';
import TwitterIcon from '../../icons/social_media/twitter-icon';
import {
  combineDiabetesConditions,
  hasDiabetes,
  hasNonfreeProgram,
  hasPreventionOnly,
} from '../../utils/possible_conditions';
import Anchor from '../anchor';

function LandingPageFooter({ showCards = true, showDevicesFinePrint = false, possibleConditionsAtEntry = [] }) {
  const diabetes = hasDiabetes(possibleConditionsAtEntry);
  const musculoskeletal = hasNonfreeProgram(possibleConditionsAtEntry);
  const conditions = combineDiabetesConditions(possibleConditionsAtEntry);

  const constructOmadaLink = (path) => window.dataFromRuby.omada_b2b_url + path;
  const links = {
    Product: {
      'Success Stories': constructOmadaLink('/success-stories'),
      'Our Approach': constructOmadaLink('/for-individuals'),
      Security: constructOmadaLink('/security'),
      'Log In': `${window.dataFromRuby.kairos_base_url}/login`,
    },
    Company: {
      About: constructOmadaLink('/who-we-are'),
      Press: constructOmadaLink('/press'),
      Careers: constructOmadaLink('/careers'),
      Blog: 'https://resourcecenter.omadahealth.com/for-individuals',
      Contact: 'https://support.omadahealth.com/hc/en-us/requests/new',
    },
    Support: {
      FAQ: constructOmadaLink('/frequently-asked-questions'),
      'Help Center': 'https://support.omadahealth.com/hc/en-us',
      Security: constructOmadaLink('/security'),
      Privacy: constructOmadaLink('/privacy-policy'),
    },
  };

  const constructLinkList = (linkSet) => {
    return Object.keys(linkSet).map((key) => (
      <li className='ev-footer-v2__link' key={linkSet[key]}>
        <Anchor
          anchorHref={linkSet[key]}
          anchorText={key}
          ariaLabel={key}
          section='footer'
          event={key === 'Log In' ? 'login' : 'info'}
        />
      </li>
    ));
  };

  const contructLinkSets = () => {
    return Object.keys(links).map((key) => (
      <ul key={key}>
        <li className='ev-footer-v2__link-set' role='heading' aria-level='4'>
          {key}
        </li>
        {constructLinkList(links[key])}
      </ul>
    ));
  };

  const constructCards = () => {
    const cardDetails = [
      {
        url: `${window.dataFromRuby.omada_b2b_url}/who-we-are`,
        header: 'Learn more about Omada',
        body: 'Our pioneering approach to behavior change is inspiring and engaging people everywhere in lifelong health.',
        img: <HeadWithBeakerBaseIcon />,
        alt: 'Learn more about Omada',
      },
      {
        url: `${window.dataFromRuby.omada_b2b_url}/success-stories`,
        header: 'Dive deep into our success stories',
        body: "Technology, data, and design are great, but it's our clinical results that really matter.",
        img: <ClinicalIcon />,
        alt: 'Dive deep into our success stories',
      },
    ];

    const cards = cardDetails.map((card) => (
      <Anchor
        anchorHref={card.url}
        ariaLabel={`${card.body} ${card.header}`}
        classNames='ev-popcorn-card popcorn-card__link-container'
        key={card.alt}
        section='footer'
        event='info'
        type='card'
      >
        {card.img}
        <div className='popcorn-card__content-container'>
          <p className='ev-popcorn-card__body u-4mb--s'>{card.body}</p>
          <p className='ev-popcorn-card__header'>
            <strong>{card.header}</strong>
          </p>
        </div>
      </Anchor>
    ));

    return (
      <div className='angled-bg-section'>
        <div className='additional-blocks'>{cards}</div>
      </div>
    );
  };

  const footer_deployment_license = window.dataFromRuby.footer_deployment_license;

  const diabetesFinePrintForCGM = () => {
    return (
      <>
        <section>
          <span className='ev-footer-mark'>†</span>
          {window.dataFromRuby.devices_in_focus_fine_print.diabetes_continuous_glucose_monitor.dagger}
        </section>
        <br />
        <section>
          <span className='ev-footer-mark'>‡</span>
          {window.dataFromRuby.devices_in_focus_fine_print.diabetes_continuous_glucose_monitor.double_dagger}
        </section>
        <br />
      </>
    );
  };

  const devicesFinePrint = () => {
    let legalSection = null;
    let noCostText = window.dataFromRuby.devices_in_focus_fine_print.no_cost;
    const offersCGM = window.dataFromRuby.show_cgm_device;
    const mskTextBlock = (
      <div>
        <br />
        {window.dataFromRuby.devices_in_focus_fine_print.musculoskeletal}
      </div>
    );

    if (hasPreventionOnly(possibleConditionsAtEntry)) {
      noCostText = noCostText.replace('<<programs>>', 'Prevention');
      legalSection = (
        <section>
          {window.dataFromRuby.devices_in_focus_fine_print.eligibility_disclaimer.non_multicondition}
          <br />
          <br />
          {noCostText}
        </section>
      );
    } else {
      const conditionsForNoCostText = conditions.filter((c) => c !== 'musculoskeletal');

      if (conditionsForNoCostText.length === 0) {
        noCostText = null;
      } else {
        const capitalizedConditions = conditionsForNoCostText.map(
          (c) => c.charAt(0).toUpperCase() + c.slice(1, c.length),
        );
        // Comma separated list except for final condition which is preceeded by "or"
        const humanReadableConditonsList = capitalizedConditions.join(', ').replace(/(,)([^,]*)$/, ' or$2');

        noCostText = noCostText.replace('<<programs>>', humanReadableConditonsList);
      }

      legalSection = (
        <section>
          {window.dataFromRuby.devices_in_focus_fine_print.eligibility_disclaimer.multicondition}
          <br />
          <br />
          {noCostText}
          {musculoskeletal && mskTextBlock}
        </section>
      );
    }

    return (
      <div className='ev-footer-fine-print' id='qa-footer-fine-print'>
        {legalSection}
        <br />
        {diabetes && offersCGM && diabetesFinePrintForCGM()}
        <br />
        {window.dataFromRuby.devices_in_focus_fine_print.images_disclaimer}
      </div>
    );
  };

  return (
    <div className='ev-footer'>
      {showCards && constructCards()}
      <div className='ev-footer__bottom-section u-flex u-align-items-center u-flex-column'>
        <div className='ev-footer-v2__link-section u-flex'>
          <div className='u-flex u-flex-column'>
            <div className='u-flex ev-footer-logo'>
              <OmadaLogo />
            </div>
            <div className='u-flex ev-footer-copyright'>
              {`Copyright ${new Date().getFullYear()} © Omada Health, Inc. All rights reserved.`}
            </div>
          </div>
          <div className='ev-footer-v2__navigation_links'>{contructLinkSets()}</div>
        </div>
        <div className='ev-footer-v2__additional_links u-4mb--m' role='list'>
          <Anchor
            anchorHref={constructOmadaLink('/hipaa-notice')}
            anchorText='HIPAA'
            ariaLabel='HIPAA Notice'
            section='footer'
            event='info'
            role='listitem'
          />
          <Anchor
            anchorHref={constructOmadaLink('/terms-of-use')}
            anchorText='Terms of Use'
            ariaLabel='Terms of Use'
            section='footer'
            event='info'
            role='listitem'
          />
          <Anchor
            anchorHref={constructOmadaLink('/member-rights-and-responsibilities')}
            anchorText='Member Rights and Responsibilities'
            ariaLabel='Member Rights and Responsibilities'
            section='footer'
            event='info'
            role='listitem'
          />
        </div>
        <hr className='u-4mb--m t-bc--grey-3 footer-v2__copyright-divider site-footer__divider' />
        <div className='ev-footer-v2__copyright-section u-4mb--m'>
          <div className='u-flex'>
            {footer_deployment_license && (
              <p
                className='ev-footer-disclaimer qa-footer-deployment-license u-flex'
                dangerouslySetInnerHTML={{ __html: footer_deployment_license }}
              />
            )}
          </div>
          <div className='ev-footer-v2__copyright-social-icon-wrapper u-4mt--xxs u-flex'>
            <Anchor
              anchorHref='https://www.facebook.com/omadahealth'
              ariaLabel='Facebook'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <FacebookIcon />
            </Anchor>

            <Anchor
              anchorHref='https://twitter.com/omadahealth'
              ariaLabel='Twitter'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <TwitterIcon />
            </Anchor>

            <Anchor
              anchorHref='https://www.linkedin.com/company/omada-health'
              ariaLabel='LinkedIn'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <LinkedinIcon />
            </Anchor>

            <Anchor
              anchorHref='https://instagram.com/omadahealth'
              ariaLabel='Instagram'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <InstagramIcon />
            </Anchor>

            <Anchor
              anchorHref='https://medium.com/omada-health'
              ariaLabel='Medium'
              classNames='u-4mr--xxs footer-v2__copyright-social-icon'
              section='footer'
              event='info'
              type='icon'
            >
              <MediumCompanyIcon />
            </Anchor>
          </div>
        </div>
        <hr className='u-4mb--m t-bc--grey-3 footer-v2__copyright-divider site-footer__divider' />
        {showDevicesFinePrint && devicesFinePrint()}
      </div>
    </div>
  );
}

const { bool, array } = PropTypes;

LandingPageFooter.propTypes = {
  showCards: bool,
  showDevicesFinePrint: bool,
  possibleConditionsAtEntry: array,
};

export default LandingPageFooter;

import PropTypes from 'prop-types';
import React from 'react';

import Anchor from '../anchor';
import CtaButton from '../cta_button';
import HeaderLogo from '../header_logo';

function LandingPageHeader({ config: { ctaText }, forceConfig }) {
  const { kairos_base_url, link_to_corporate_site, logo } = window.dataFromRuby;

  return (
    <div className='landing_page__header landing_page__header--stickyHeader'>
      <div className='landing_page__header-container u-pt--n u-pb--n'>
        <div className='landing_page__header-left' style={{ display: 'flex', verticalAlign: 'middle' }}>
          <HeaderLogo kairos_base_url={kairos_base_url} link_to_corporate_site={link_to_corporate_site} logo={logo} />
        </div>
        <div className='landing_page__header-right qa-header' style={{ display: 'flex' }}>
          <Anchor
            anchorHref={`${kairos_base_url}/login`}
            anchorText='Log in'
            ariaLabel='Log in'
            classNames='ev-text-action-primary--sm u-4mt--s u-4mb--s'
            section='header'
            event='login'
            type='button'
          />

          <CtaButton
            classNames='ev-button-primary--sm u-4mt--s u-4mb--s'
            ctaText={ctaText}
            forceConfig={forceConfig}
            section='header'
          />
        </div>
      </div>
    </div>
  );
}

const { bool, shape, string } = PropTypes;

LandingPageHeader.propTypes = {
  config: shape({
    ctaText: string,
  }),
  forceConfig: bool,
};

export default LandingPageHeader;
